import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PersonIcon from "@mui/icons-material/Person";
import Button from "@mui/material/Button";
import Navbar from "../../Components/Navbar"
import React, {useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

// import cookies 
import Cookies from 'universal-cookie';

// importing services 
import {getProfile, editProfile} from '../../services/Profile';

// MUI Alert Initialization
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
var userRole = "";
// Page to display profile information for the current user
export default function Profile() {
  // routing
  const navigate = useNavigate();
  
  const [backendUser, setBackendUser] = useState({});

  // states for fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [schoolDistrict, setSchoolDistrict] = useState("");
  const [schoolPhone, setSchoolPhone] = useState("");
  const [schoolAddress, setSchoolAddress] = useState("");
  const [preferredTheme, setPreferredTheme] = useState("");
  const [isEditable, setIsEditable] = useState(true);

  // states for alert messages
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    userRole = cookies.get('role');
    if(cookies.get('access') === undefined){
      navigate("/");
    }
    

    // fetch profile for fields init
    const fetchProfile = async () => {
      const res = await getProfile();
      setFirstName(res.first_name)
      setLastName(res.last_name)
      setEmail(res.email)
      setPhoneNumber(res.phone_number)
      setBackendUser(res)
      setSchoolDistrict(res.school_district)
      setSchoolPhone(res.school_phone_number)
      setSchoolAddress(res.school_mailing_address)
      setPreferredTheme(res.theme_for_the_week)
    }

    fetchProfile()

  }, [navigate]);

  // change profile handler
  const profileHandler = async () => {
    const data = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      school_district: schoolDistrict,
      school_phone_number: schoolPhone,
      school_mailing_address: schoolAddress,
      theme_for_the_week: preferredTheme
    };
    try{
      await editProfile(data);
      setSuccess(true)
      setBackendUser(data)
      setIsEditable(true)
    }catch{
      setFailure(true)
    }

  }


  return (
    <main>
        <Navbar/>
        
        <div className="pl-5 md:pl-40">

        </div>
      <Box
        component="div"
        data-testid="profile-component"
        style={{ position: "absolute", left: "30%", marginTop: "2rem" }}
        sx={{ p: 5, border: "1px solid #DCDCDC	", maxWidth: 500 }}
      >
        <PersonIcon
          style={{
            fontSize: "3rem",
            marginLeft: "11.5rem",
            marginBottom: "2rem",
          }}
        />
        <div className="text-center">
          <div className="md:flex">
            <TextField
              disabled={isEditable}
              required
              id="standard-required"
              label="First Name"
              style={{ marginBottom: "1.25rem", marginRight: "5rem" }}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value.replace(/[^\w\s]/gi, ""))}
              variant="standard"
            />

            <TextField
              disabled={isEditable}
              required
              id="standard-required"
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value.replace(/[^\w\s]/gi, ""))}
              variant="standard"
            />
          </div>

          <div className="md:flex">
            <TextField
              disabled={isEditable}
              required
              id="standard-required"
              label="Email"
              style={{ marginBottom: "1.25rem", marginRight: "1rem" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="standard"
            />
          </div>
          <div className="md:flex">
            <TextField
              disabled={isEditable}
              id="standard-required"
              label="Personal Phone Number"
              style={{ marginBottom: "1.25rem", marginRight: "5rem" }}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              variant="standard"
              type={"tel"}
            />
          </div>
          {userRole === "TEACHER" && (
            <div>
            <div className="md:flex">
            <TextField
              disabled={isEditable}
              id="standard-required"
              label="School District"
              style={{ marginBottom: "1.25rem", marginRight: "5rem" }}
              value={schoolDistrict}
              onChange={(e) => setSchoolDistrict(e.target.value)}
              variant="standard"
            />
            </div>
            <div className="md:flex">
            <TextField
              disabled={isEditable}
              id="standard-required"
              label="School Email"
              style={{ marginBottom: "1.25rem", marginRight: "5rem" }}
              value={schoolAddress}
              onChange={(e) => setSchoolAddress(e.target.value)}
              variant="standard"
            />
          </div>
          <div className="md:flex">
            <TextField
              disabled={isEditable}
              id="standard-required"
              label="School Phone"
              style={{ marginBottom: "1.25rem", marginRight: "5rem" }}
              value={schoolPhone}
              onChange={(e) => setSchoolPhone(e.target.value)}
              variant="standard"
            />
          </div>
          <div className="md:flex">
            <TextField
              disabled={isEditable}
              id="standard-required"
              label="Preferred Theme"
              style={{ marginBottom: "1.25rem", marginRight: "5rem" }}
              value={preferredTheme}
              onChange={(e) => setPreferredTheme(e.target.value)}
              variant="standard"
            />
          </div>
            </div>
          )}
          <Button
            variant="contained"
            //disabled={backendUser.first_name === firstName && backendUser.last_name === lastName && backendUser.email === email}
            onClick = {()=>{setIsEditable(false);}}
            style={{ marginTop: "2rem", marginRight: "2rem", marginLeft: "6rem"  }}
          >
            <EditIcon />Edit
          </Button>
          <Button
            variant="contained"
            //disabled={backendUser.first_name === firstName && backendUser.last_name === lastName && backendUser.email === email}
            onClick = {profileHandler}
            style={{ marginTop: "2rem", marginRight: "5rem"  }}
          >
            Save
          </Button>
        {/* Success Alert */}
        <Snackbar open={success} onClose={() => setSuccess(false)} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
          <Alert severity="success" sx={{ width: '100%' }} onClose={() => setSuccess(false)}>
            Profile Edited Successfully!
          </Alert>
        </Snackbar>

        {/* Failure Alert */}
        <Snackbar open={failure} onClose={() => setFailure(false)} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
          <Alert severity="error" sx={{ width: '100%' }} onClose={() => setFailure(false)}>
            Profile Edit Failed!
          </Alert>
        </Snackbar>
        </div>
      </Box>
    </main>
  );
}