import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AccountsDropDown from '../../DropDownMenu/AccountType';
import plusImg from './../assets/plus.svg'
import axios from "axios";
import Cookies from 'universal-cookie'

// Function exports the dialog for adding accounts
// Only admin users should be able to use the page with the dialog in it
// Do not use dialog on pages that are not for admin only as there is no check for current user role

export default function AddAccountDialog(props) {

  // Creation of States used by the function
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [role, setRole] = React.useState('');
  const cookies = new Cookies();

  // Handler Functions for updating the states
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setOpen(false);
    const data = {email: email, role: role};

    await props.addUser(data).then(res => {
      if (role === "PRESENTER"){
        alert("Email sent with the newly added account to the presenter. \nPlease assign the presenter to a class.");
      }
      if (role === "ADMIN"){
        alert("Email sent with the newly added account to the admin.");
      }
      if (role === "TEACHER"){
        const newClass = {
          "school": "",
          "grade": 0,
          "classroom": "",
          "teacher": [email],
          "admin": cookies.get('uEmail'),
          "presenter": [],
        };
        const headers = {
          'Authorization': 'Bearer ' + cookies.get('access'),
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Accept': "*/*" 
        }
        axios
          .post(`${process.env.REACT_APP_API_URL}/courses/`, 
              newClass, 
              {headers: headers})
          .then(res => {
            alert("Email sent with the newly added account to the teacher.\nA class has been created for the teacher.");
          });
      }
    });

  };

  const handleInputEmail = (event) => {
    setEmail(event.target.value);
  };

    //handle role change
  const handleInputRole = (event) => {
    setRole(event.target.value);
  }

  return (
    <div>
      {/* Button will always be visible and if clicked will make the dialog visible */}
        <button
        style={{marginBottom: "10px"}}
          data-testid="create-student-button-1"
          onClick={handleClickOpen}
          className="border p-4 rounded-full flex items-center shadow-xl hover:shadow-2xl float-right"
        >
          <img src={plusImg} alt="create_event" className="w-7 h-7" />
          <span className="pl-2 pr-6 text-xl font-semibold text-green-900">Add Account</span>
        </button>
      <Dialog open={open} onClose={handleClose} className="form">
        <DialogTitle>Create New Account</DialogTitle>
        <DialogContent>
          {/* Input feilds with information on the student */}
          <TextField className='text'
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            onChange={handleInputEmail}
          />
          <AccountsDropDown
            role= {''}
            handleInputRole={handleInputRole}
            display='inline'/>
        </DialogContent>
        <DialogActions
          display='inline'>
          <Button onClick={handleClose} data-testid="users-cancel">Cancel</Button>
          <Button onClick={handleSubmit} data-testid="users-create">Create</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
