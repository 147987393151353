import React, {useContext} from 'react'
import plusImg from './../assets/plus.svg'
import AdminContext from './../context/AdminContext';

// Button to add a new class
// When clicked, it opens the modal to add a new class
export default function CreateClassButton() {
    const {setShowClassModal } = useContext(AdminContext);
    return (
      <button
        data-testid="create-class-button-1"
        onClick={() => {
          setShowClassModal(true);
        }}
        className="border p-4 rounded-full flex items-center shadow-xl hover:shadow-2xl"
      >
        <img src={plusImg} alt="create_event" className="w-7 h-7" />
        <span className="pl-2 pr-6 text-xl font-semibold text-green-900">Add Class</span>
      </button>
    );
  }