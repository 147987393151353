import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// Drop down to select if account is Active on Inactive
// Inactive accounts will not be able to be logged into
export default function ActiveDropDown(props) {

  const flag = props.is_active ? "Active":"Inactive";

  return (
    <Box sx={{ minWidth: '50%' }}>
     <FormControl 
        fullWidth
        margin='dense'>
          <InputLabel id="active-select">Active?</InputLabel>
          <Select
            className='drop-down'
            labelId="active-select"
            id="active-select"
            label="Activity Flag"
            onChange={props.handleActive}
            defaultValue={flag}
            required
            >
            <MenuItem value={"Active"}>Active</MenuItem>
            <MenuItem value={"Inactive"}>Inactive</MenuItem>
          </Select>
        </FormControl>
    </Box>
  );
}