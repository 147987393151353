import axios from "axios";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

// new user add to db via axios
export default function editUser(data){
  return axios.put(`${process.env.REACT_APP_API_URL}/profile/${data.id}`, data, {headers: {
    'Content-Type': 'application/json',
    'Accept': '*/*',
    'Access-Control-Allow-Origin': '*',
    'Authorization' : 'Bearer '+ cookies.get('access')
  }})
}