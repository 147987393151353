import React, {useContext, useState, useEffect} from 'react'
import GlobalContext from './context/Context'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Comments from './comments/Comments';
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import "./comment.css";
import axios from "axios";
import { sizeHeight } from '@mui/system';
var courseId = '1';
export default function CommentModal() {
    // Get variables from the global context
    const {
        setShowCommentModal, 
    } = useContext(GlobalContext);
    const cookies = new Cookies();
    const navigate = useNavigate();
    const role = cookies.get('role');
    const currentUserId = Number(cookies.get('uid'));

    // Go home if course is not selected
    useEffect(() => {
        if(cookies.get('courseId') === undefined){
            navigate('/home');
        }
        else{
            courseId = (cookies.get('courseId'));
        }
    }, [])


    const headers = {
        'Authorization': 'Bearer ' + cookies.get('access'),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': "*/*" 
    }

  return (
<div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center" data-testid="event-modal-1" style={{zIndex: '5'}}>
  <div className="bg-white rounded-lg shadow-2xl w-2/3 h-4/5">
    <header className="bg-gray-100 px-4 py-2 flex justify-between items-center">
      <span className="material-icons-outlined text-gray-400">drag_handle</span>
      <div>
        <button onClick={() => setShowCommentModal(false)}>
          <span className="material-icons-outlined text-gray-400">close</span>
        </button>
      </div>
    </header>
    <div style={{overflowY: 'scroll', maxHeight: 'calc(100% - 6%)'}}>
      <style>
        {`
          ::-webkit-scrollbar {
            width: 8px;
          }

          ::-webkit-scrollbar-track {
            background-color: transparent;
          }

          ::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            border: 2px solid transparent;
          }

          ::-webkit-scrollbar-thumb:hover {
            background-color: rgba(0, 0, 0, 0.4);
          }
        `}
      </style>
      <div style={{margin:'15px'}}>
        <Comments  currentUserId={currentUserId}/>
      </div>
      
    </div>
  </div>
</div>


  );
}
