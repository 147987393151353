import React from 'react';
import UploadIcon from '@mui/icons-material/Upload';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
// Button to import students from a CSV file
export default function ImportStudentButton() {
  const handleImportButtonClick = () => {
    document.getElementById('myFileInput').click();
  };

  const handleRightButtonClick = () => {
    // Define the CSV data
    const csvData =
      'FirstName,Last Name,Email,ASN,ID Release,Photos,Videos,Written Work,Voice,Research,Email Address\r\n' +
      'Scott,Nguyen,sample@ualberta.ca,123456789,Yes,Yes,Yes,Yes,,Yes,Yes\r\n' +
      'Heidi,Duncan,example@gmail.com,,No,,No,No,,No,Yes\r\n' +
      'Tammy,King,,987654322,Yes,No,Yes,No,Yes,Yes,Yes\r\n' +
      'Jeffrey,Guerrero,123@outlook.com,0,No,Yes,Yes,,Yes,No,Yes\r\n';

    // Create a blob object from the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a download link and click it
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'students.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="flex justify-between items-center">
      <button
        data-testid="import-student-button-1"
        onClick={() => {
          document.getElementById('myFileInput').click();
      }}
        className="border p-4 rounded-l-full flex items-center shadow-xl hover:shadow-2xl"
      >
        <div className="w-7 h-7">
          <UploadIcon style={{ color: 'green' }} />
        </div>
        <span className="pl-1 pr-7 text-xl font-semibold text-green-900">
          Import from CSV
        </span>
      </button>
      <button
        data-testid="right-button"
        onClick={handleRightButtonClick}
        className="border p-4 rounded-r-full flex items-center shadow-xl hover:shadow-2xl"
      >
        <div className="w-7 h-7">
          <SimCardDownloadIcon style={{ color: 'green' }} />
        </div>
        <span className="pl-2 pr-2 text-xl font-semibold text-green-900">
          Sample CSV
        </span>
      </button>
    </div>
  );
}
