import React, {useContext} from 'react'
import CommentIcon from '@mui/icons-material/Comment';
import GlobalContext from './context/Context';

// Button to open the event modal where the user can create a new event
export default function CommentButton() {
    const { showCommentModal, setShowCommentModal } = useContext(GlobalContext);
    return (
      <button 
        data-testid="comment-button-1"
        onClick={() => {
            setShowCommentModal(true);
        }}
        className="border p-3 rounded-full flex items-center shadow-lg hover:shadow-2xl"
      >
          <div className="w-7 h-7" >
              <CommentIcon style={{color: 'green'}}/>
          </div>
        <span className="pl-1 pr-7 text-md font-semibold text-green-900">Comment</span>
      </button>
    );
  }


