import React, {useContext, useState, useEffect} from 'react'
import GlobalContext from './context/Context'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import "./style_gridss.css";
import Multiselect from 'multiselect-react-dropdown';
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import axios from "axios";
//const labelsClasses = ["green", "blue", "teal", "maroon", "darkmagenta", "chocolate", "gray", "crimson", "goldenrod"];
const labelsClasses = ["#008000", "#0000FF", "#008080", "#800000", "#8b008b", "#d2691e", "#808080", "#dc143c", "#daa520"];
var courseId = '1';

export default function EventModal() {
    // Get variables from the global context
    const {
        setShowEventModal, 
        dispatchCalEvent, 
        selectedEvent, 
        changes,
        updateChanges,
        monday,
        presenters
    } = useContext(GlobalContext);
    const cookies = new Cookies();
    const navigate = useNavigate();
    
    const role = cookies.get('role');
    
    // Go home if course is not selected
    useEffect(() => {
        if(cookies.get('courseId') === undefined){
            navigate('/home');
        }
        else{
            courseId = (cookies.get('courseId'));
        }
    }, [])

    const [endTime, setEndTime] = useState(selectedEvent ? selectedEvent.end : monday);
    const [startTime, setStartTime] = useState(selectedEvent ? selectedEvent.start : monday);
    // const [description, setDescription] = useState(selectedEvent ? selectedEvent.title : "");
    const [description, setDescription] = useState(selectedEvent ? (selectedEvent.title.includes(' 📍 ') ? selectedEvent.title.substring(0, selectedEvent.title.indexOf(' 📍 ')) : selectedEvent.title)  : "");
    const [location, setLocation] = useState(selectedEvent ? selectedEvent.location : "");
    const [presentersList, setPresentersList] = useState(selectedEvent ? selectedEvent.presenters : []);
    
    // Color label for the event, if non is selected, default to indigo
    const [selectedLabel, setSelectedLabel] = useState(
        selectedEvent
            ? labelsClasses.find((lbl) => lbl === selectedEvent.color)
            : labelsClasses[0]
        );

    const headers = {
        'Authorization': 'Bearer ' + cookies.get('access'),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': "*/*" 
    }
    // Submit the event to the calendar, currently saved to local storage
    // Supports updating and creating new events
    function handleSubmit(e) {
        e.preventDefault()
        if (startTime > endTime) {
            alert("Start time cannot be after end time");
            return;
        }
        // If role is not admin, do not allow to submit
        if (role !== "ADMIN"){
            setShowEventModal(false);
            return;
        }
        const calendarEvent ={
            title: description+":location:"+location,
            start: new Date(startTime),
            end: new Date(endTime),
            color: selectedLabel,
            id: selectedEvent? selectedEvent.id : Date.now(),
            presenters: presentersList
        };
        if (selectedEvent){
            // UPDATE selected event
            axios
            .patch(`${process.env.REACT_APP_API_URL}/schedule/${courseId}`, calendarEvent,
                {headers: headers})
            .then(res => {
                // Close the modal and refresh the table
                updateChanges(changes + 1);
                setShowEventModal(false);
            });
        } else {
            // POST new event to database
            axios
            .post(`${process.env.REACT_APP_API_URL}/schedule/${courseId}`, calendarEvent,
                {headers: headers})
            .then(res => {
                // Close the modal and refresh the table
                updateChanges(changes + 1);
                setShowEventModal(false);
            });
        }
        setShowEventModal(false);
    }
    function handleDelete(e) {
        e.preventDefault()
        if (selectedEvent){
            // DELETE selected event
            axios
            .delete(`${process.env.REACT_APP_API_URL}/schedule/${courseId}`, 
                {headers: headers, data: {id: selectedEvent.id}})
            .then(res => {
                // Close the modal and refresh the table
                updateChanges(changes + 1);
                setShowEventModal(false);
            });
        }
    }
  return (
    <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center"
        data-testid="event-modal-1"
        style={{zIndex: '5'}}>
        <form className="bg-white rounded-lg shadow-2xl w-1/2">
            <header className="bg-gray-100 px-4 py-2 flex justify-between items-center">
                <span className="material-icons-outlined text-gray-400">
                    drag_handle
                </span>
                <div>
                    {role === "ADMIN" && selectedEvent && (
                        <span 
                        onClick={handleDelete}
                        className="material-icons-outlined text-gray-400 cursor-pointer">
                            delete
                        </span> 
                    )}
                    <button onClick={() => setShowEventModal(false)}>
                        <span className="material-icons-outlined text-gray-400">
                            close
                        </span>
                    </button>
                </div>

            </header>
            <div className="container" style={{margin: '10px'}}>
                <div className="gDescription" style={{margin: '15px', marginRight: '25px'}}>
                    <input
                        type="text" 
                        name="description" 
                        placeholder="Add Description" 
                        value={description} 
                        required
                        className="pt-3 border-0 
                            text-gray-600 text-l 
                            font-semibold pb-2 
                            w-full border-b-2 
                            border-gray-200 
                            focus:outline-none 
                            focus:ring-0
                            focus:border-blue-500"
                        disabled={role !== "ADMIN"}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>



                <div className="gIconLoc" style={{margin: '15px', marginRight: '25px', marginTop: '30px', alignContent: 'center'}}>
                    <span className="material-icons-outlined text-gray-400">
                        room
                    </span>
                </div>
                <div className="gLocation" style={{margin: '15px', marginRight: '5%', marginLeft:'0%'}}>
                    <input
                        type="text" 
                        name="location" 
                        placeholder={role === "ADMIN" ? "Add Location" : "Unspecified"}
                        value={location} 
                        required
                        className="pt-3 border-0 
                            text-gray-600 text-l 
                            font-semibold pb-2 
                            w-full border-b-2 
                            border-gray-200 
                            focus:outline-none 
                            focus:ring-0
                            focus:border-blue-500"
                        disabled={role !== "ADMIN"}
                        onChange={(e) => setLocation(e.target.value)}
                    />
                </div>
                
                <div className="gIcon" style={{margin: '15px', marginRight: '25px', marginTop: '30px', alignContent: 'center'}}>
                    <span className="material-icons-outlined text-gray-400">
                        schedule
                    </span>
                </div>
                <div className="gStart" style={{margin: '15px', marginRight: '5%', marginLeft:'0%'}}>
                    <DatePicker 
                        className='pt-3 border-0 
                            text-gray-600  
                            font-semibold pb-2 
                            w-full border-b-2 
                            border-gray-200 
                            focus:outline-none 
                            focus:ring-0
                            focus:border-blue-500'
                        disabled={role !== "ADMIN"}
                        placeholderText='Start Time'
                        selected={startTime} 
                        onChange={date=>{
                            setStartTime(date);
                            setEndTime(date);
                        }}
                        minTime= {new Date().setHours(8,0,0,0)}
                        maxTime= {new Date().setHours(15,0,0,0)}
                        dateFormat="MMM-dd-yyyy hh:mm a"
                        timeIntervals={15}
                        showTimeSelect
                    />
                </div>
                <div className="gEnd" style={{margin: '15px', marginRight: '25px'}}>
                    <DatePicker 
                        className='pt-3 border-0 
                            text-gray-600 text-m 
                            font-semibold pb-2 
                            w-full border-b-2 
                            border-gray-200 
                            focus:outline-none 
                            focus:ring-0
                            focus:border-blue-500'
                        disabled={role !== "ADMIN"}
                        placeholderText='End Time'
                        selected={endTime} 
                        onChange={date=>setEndTime(date)}
                        minTime= {new Date().setHours(8,0,0,0)}
                        maxTime= {new Date().setHours(15,0,0,0)}
                        dateFormat="MMM-dd-yyyy hh:mm a"
                        timeIntervals={15}
                        showTimeSelect
                    />
                </div>
                <div className="gIconP" style={{margin: '15px', marginRight: '25px', marginTop: '30px', alignContent: 'center'}}>
                    <span className="material-icons-outlined text-gray-400">
                        <CoPresentOutlinedIcon/>
                    </span>
                </div>
                <div className="gPresenter" style={{margin: '0px', marginRight: '10%', marginLeft:'0%'}}> 
                    <Multiselect
                        closeOnSelect={true}
                        className="pt-3 border-0 
                        text-gray-600 text-l 
                        font-semibold pb-2 
                        border-gray-200 
                        focus:outline-none 
                        focus:ring-0
                        focus:border-blue-500"
                        placeholder='Presenters'
                        isObject={false}
                        disable={role !== "ADMIN"}
                        options={presenters.filter(presenter =>
                            !presentersList.some(p => p.includes(presenter) || presenter.includes(p))
                          )}
                        value={presentersList}
                        selectedValues={presentersList}
                        onSelect={(e) => setPresentersList(e)}
                        onRemove={(e) => setPresentersList(e)}
                        // style multiselect container
                        style={{
                            closeOnSelect: true,
                            chips: {
                                background: 'green'
                            },
                            // Remove outline from multiselect on focus
                            searchBox: {
                                outline: 'none'
                            },
                        }}
                    />
                </div>
                <div className="gIcon2" style={{margin: '15px', marginRight: '25px', marginTop: '30px', alignContent: 'center'}}>
                    <span className="material-icons-outlined text-gray-400">
                        bookmark_border
                    </span>
                </div>
                <div className="gColor flex gap-x-5" style={{margin: '15px', marginRight: '25px', marginTop: '30px', alignContent: 'center'}} >
                    {labelsClasses.map((lblClass, i)=>(
                        <span key = {i}
                            onClick={role === "ADMIN" ? () => setSelectedLabel(lblClass) : null}
                            // Create a circle using this color #F87171
                            className={`bg-${lblClass} w-6 h-6 rounded-full flex items-center justify-center cursor-pointer`}
                            style={{ backgroundColor: lblClass }}
                            //className={`bg-${lblClass} w-6 h-6 rounded-full flex items-center justify-center cursor-pointer `}
                        >
                            {selectedLabel === lblClass && (
                            <span className="material-icons-outlined text-white text-sm">
                                check
                            </span>
                            )}
                        </span>
                    ))}
                </div>
                <div className="gSave" style={{position: "relative"}}>
                    <button type="submit" 
                            onClick={handleSubmit}
                            className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
                            style={{position: "absolute", right: "30px", bottom: "10px"}}>
                        {role === "ADMIN" ? "Save" : "Close"}
                    </button>
                </div>
            </div>

        </form>
    </div>
  );
}
