import {Calendar, momentLocalizer} from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import overlap from 'react-big-calendar/lib/utils/layout-algorithms/overlap'
import React, {useState, useContext, useEffect, createRef} from "react";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Navbar from '../../Components/Navbar';
import Sidebar from './Sidebar';
import GlobalContext from './context/Context';
import EventModal from './EventModal';
import ConfirmModal from './ConfirmModal';
import { TbArrowsMaximize, TbArrowsMinimize } from 'react-icons/tb';
import {RiScreenshot2Fill} from 'react-icons/ri';
import DeleteAllModal from './DeleteAllModal';
import CommentModal from './CommentModal';
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import './cal.css'
import { createFileName, useScreenshot } from 'use-react-screenshot';
import { set } from 'date-fns';
var courseId=1;


const localizer = momentLocalizer(moment)
//Start of week is monday instead of sunday
moment.updateLocale('en', {
    week: {
        dow: 1,
    }
});
// This is the main page for the schedule
function UserSchedulePage(){
    const cookies = new Cookies();
    const navigate = useNavigate();

    useEffect(() => {
    const cookies = new Cookies();
    if(cookies.get('access') === undefined){
        navigate("/");
    }

    }, [navigate]);
    const role = cookies.get('role');
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [storedEvents, setStoredEvents] = useState([]);
    const [firstDate, setFirstDate] = useState(new Date());
    const [image, takeScreenshot] = useScreenshot({
        type: "image/jpeg",
        quality: 1.0
    });
    const ref = createRef(null);

    const download = (image, { name = school, extension = "png" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };
    const toggleFullScreen = () => {
        
        setIsFullScreen((prev) => !prev);        
    };
    const{ 
        changes, updateChanges,
        setMonday,
        setTuesday,
        setWednesday,
        setThursday,
        setFriday,
    } = useContext(GlobalContext);
    const {
        showEventModal, 
        showConfirmModal,
        showCommentModal,
        savedEvents, 
        setSelectedEvent, 
        selectedEvent, 
        setShowEventModal,
        setFacilitator,
        setTeacher,
        setPresenters,
        setSchool,
        school,
        setGrade,
        setClassroom,
        classroom,
        showDeleteModal
    } = useContext(GlobalContext);

    // Set get the days of the current week
    useEffect(() => {
        setMonday(moment().startOf('week').toDate());
        setTuesday(moment().startOf('week').add(1, 'days').toDate());
        setWednesday(moment().startOf('week').add(2, 'days').toDate());
        setThursday(moment().startOf('week').add(3, 'days').toDate());
        setFriday(moment().startOf('week').add(4, 'days').toDate());
    }, []);
    function onNaviate(date, view, action){
        setFirstDate(date);
        // If the user navigates a different week, set the dates to the next week
        if(view === 'work_week'){
            setMonday(moment(date).startOf('week').toDate());
            setTuesday(moment(date).startOf('week').add(1, 'days').toDate());
            setWednesday(moment(date).startOf('week').add(2, 'days').toDate());
            setThursday(moment(date).startOf('week').add(3, 'days').toDate());
            setFriday(moment(date).startOf('week').add(4, 'days').toDate());
        }
    }

    // Go home if course is not selected
    useEffect(() => {
        if(cookies.get('courseId') === undefined){
            navigate('/home');
        }
        else{
            courseId = (cookies.get('courseId'));
        }
    }, [])

    const headers = {
        'Authorization': 'Bearer ' + cookies.get('access'),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': "*/*" 
      }

    // Get the events from the database
    async function getEvents(){
        await axios
        .get(`${process.env.REACT_APP_API_URL}/schedule/${courseId}`,
            {headers: headers})
        .then(res => {
            setFacilitator(res.data.admin);
            setTeacher(res.data.teacher);
            setPresenters(res.data.presenters);
            setSchool(res.data.course.school);
            setGrade(res.data.course.grade);
            setClassroom(res.data.course.classroom);
            
            var convertedEvents = res.data.events;
            // Convert the events to the format that react-big-calendar uses
            for(var i = 0; i < convertedEvents.length; i++){
                convertedEvents[i].start = new Date(convertedEvents[i].start);
                convertedEvents[i].end = new Date(convertedEvents[i].end);
                // Change the title to first part of title
                convertedEvents[i].location = convertedEvents[i].title.split(":location:")[1];
                if (convertedEvents[i].location.length == 0){
                    convertedEvents[i].title = convertedEvents[i].title.split(":location:")[0];
                }else{
                    convertedEvents[i].title = convertedEvents[i].title.split(":location:")[0] + ' 📍 ' + convertedEvents[i].location;
                }
            }
            setStoredEvents(convertedEvents);
        })
    }
    // Get the events from the database
    async function getEventsFirst(){
        await axios
        .get(`${process.env.REACT_APP_API_URL}/schedule/${courseId}`,
            {headers: headers})
        .then(res => {
            setFacilitator(res.data.admin);
            setTeacher(res.data.teacher);
            setPresenters(res.data.presenters);
            setSchool(res.data.course.school);
            setGrade(res.data.course.grade);
            setClassroom(res.data.course.classroom);
            
            var convertedEvents = res.data.events;
            // Convert the events to the format that react-big-calendar uses
            for(var i = 0; i < convertedEvents.length; i++){
                convertedEvents[i].start = new Date(convertedEvents[i].start);
                convertedEvents[i].end = new Date(convertedEvents[i].end);
                // Change the title to first part of title
                convertedEvents[i].location = convertedEvents[i].title.split(":location:")[1];
                if (convertedEvents[i].location.length == 0){
                    convertedEvents[i].title = convertedEvents[i].title.split(":location:")[0];
                }else{
                    convertedEvents[i].title = convertedEvents[i].title.split(":location:")[0] + ' 📍 ' + convertedEvents[i].location;
                }
            }
            if (convertedEvents.length > 0){
                // Get min of convertedEvents.start
                var min = convertedEvents[0].start;
                for(var i = 0; i < convertedEvents.length; i++){
                    if(convertedEvents[i].start < min){
                        min = convertedEvents[i].start;
                    }
                }
                setFirstDate(min);
                setMonday(moment(min).startOf('week').toDate());
                setTuesday(moment(min).startOf('week').add(1, 'days').toDate());
                setWednesday(moment(min).startOf('week').add(2, 'days').toDate());
                setThursday(moment(min).startOf('week').add(3, 'days').toDate());
                setFriday(moment(min).startOf('week').add(4, 'days').toDate());
            }
            setStoredEvents(convertedEvents);
        })
    }
    // Refresh Events
    useEffect(() => {
        getEvents();
    }, [changes]);
    useEffect(() => {
        getEventsFirst();
    }, []);

    // Open the modal when an event is clicked for editing
    const handleSelected = (event) =>{
        setSelectedEvent(event);
        setShowEventModal(true);
    };
    const takesc = () => {
        takeScreenshot(ref.current).then(download);
    }
    return(
            <React.Fragment>
                {showEventModal && <EventModal/>}    
                {showConfirmModal && <ConfirmModal/>}
                {showCommentModal && <CommentModal/>}
                {showDeleteModal && <DeleteAllModal/>}
                <div className = "h-screen flex flex-col">
                    <Navbar/>
                    <div className = "flex flex-1" ref={ref}>
                        <Sidebar />
                        <Calendar 
                            style={{ fill: true}} 
                            className={`flex-1 ${isFullScreen ? 'full-screen' : 'not-full-screen'}`}
                            date={firstDate}
                            onNavigate={onNaviate}
                            dayLayoutAlgorithm={(params)=>{
                                return overlap({ ...params, minimumStartDifference: 15 })
                            }}
                            min={new Date(2017, 10, 0, 9, 0, 0)}
                            max={new Date(2017, 10, 0, 15, 0, 0)} 
                            defaultView='work_week'
                            localizer={localizer} 
                            events={storedEvents} 
                            startAccessor="start" 
                            endAccessor="end" 
                            views={['month', 'work_week', 'day']}
                            eventPropGetter={(events) => {
                                const backgroundColor = events.color;
                                return { style: { backgroundColor } }
                            }}
                            selected={selectedEvent}
                            onSelectEvent={handleSelected}
                        />
                    </div>
                    <button className="fullscreen-button" style={{zIndex:101}}>
                        {isFullScreen 
                            ? <div>
                                <RiScreenshot2Fill className='min-icon' onClick={takesc} style={{marginBottom: '0.5rem'}}/>   
                                <TbArrowsMinimize className='min-icon' onClick={toggleFullScreen}/>  
                              </div>
                            : <TbArrowsMaximize className='max-icon' onClick={toggleFullScreen}/>
                              
                            }
                    </button>
                </div>
            </React.Fragment>
    );
}
export default UserSchedulePage;