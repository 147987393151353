import React from 'react'

// TeacherContext provides the context for the teacher home page
// It is used to store the state of the modal for adding a new student
// It also allows components to share these declared variables.
const TeacherContext = React.createContext({
    showStudentModal: false,
    setShowStudentModal: () => {},
    changes: 0,
    updateChanges: () => {},
    firstName: "",
    setFirstName: () => {},
    lastName: "",
    setLastName: () => {},
    email: "",
    setEmail: () => {},
    ASN: "",
    setASN: () => {},
    idRelease: false,
    setIdRelease: () => {},
    photos: false,
    setPhotos: () => {},
    videos: false,
    setVideos: () => {},
    writtenWork: false,
    setWrittenWork: () => {},
    voice: false,
    setVoice: () => {},
    research: false,
    setResearch: () => {},
    emailAddress: false,
    setEmailAddress: () => {},
    editStudent: false,
    setEditStudent: () => {},
    selectedStudentID: "",
    setSelectedStudentID: () => {},

    showTeacherCheckAccModal: false,
    setShowTeacherCheckAccModal: () => {},
});

export default TeacherContext
