import React, {useContext} from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GlobalContext from './context/Context';

// Button to create default events
export default function DeleteAllEventButton() {
    const { setShowDeleteModal } = useContext(GlobalContext);
    return (
      <button 
        data-testid="create-default-event-button-1"
        onClick={() => {
            setShowDeleteModal(true);
        }}
        className="border p-3 rounded-full flex items-center shadow-lg hover:shadow-2xl"
      >
        <div className="w-7 h-7" >
            <DeleteForeverIcon style={{color: 'red'}}/>
        </div>
        <span className="pl-1 pr-7 text-md font-semibold text-red-900">Delete All Events</span>
      </button>
    );
  }