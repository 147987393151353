import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import KeyIcon from '@mui/icons-material/Key';
import Button from "@mui/material/Button";
import Navbar from "../../Components/Navbar"
import React, {useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


// import cookies 
import Cookies from 'universal-cookie';

// importing services 
import {changePassword} from '../../services/Profile';

// MUI Alert Initialization
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Profile() {
  // routing
  const navigate = useNavigate();

  // states for fields
  const [prevPass, setPrevPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");


  // states for alert messages
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [dontMatch, setDontMatch] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    if(cookies.get('access') === undefined){
      navigate("/");
    }
  }, [navigate]);

  // change profile handler
  const passwordHandler = async () => {
    const data = {
      current_password: prevPass,
      new_password: newPass,
    };
    
    try{
      if (newPass === confirmPass){
        await changePassword(data);
        setSuccess(true)
      }else{
        setDontMatch(true)
      }
    }catch{
      setFailure(true)
    }

  }


  return (
    <main>
        <Navbar/>
        
        <div className="pl-5 md:pl-40">

        </div>
      <Box
        component="div"
        data-testid="pass-component"
        style={{ position: "absolute", left: "30%", marginTop: "2rem" }}
        sx={{ p: 5, border: "1px solid #DCDCDC	", maxWidth: 500 }}
      >
        <KeyIcon
          style={{
            fontSize: "3rem",
            marginLeft: "11.5rem",
            marginBottom: "2rem",
          }}
        />
        <div className="text-center">
          <div className="">
            <TextField
              required
              id="standard-required"
              label="Current Password"
              type="password"
              style={{ marginBottom: "1.25rem", marginRight: "5rem",marginLeft: "5rem"}}
              onChange={(e) => setPrevPass(e.target.value)}
              variant="standard"
            />

          <TextField
              required
              id="standard-required"
              label="New Password"
              type="password"
              style={{ marginBottom: "1.25rem", marginRight: "5rem", marginLeft: "5rem"}}
              onChange={(e) => setNewPass(e.target.value)}
              variant="standard"
            />
            <TextField
              required
              id="standard-required"
              label="Confirm New Password"
              type="password"
              style={{ marginBottom: "1.25rem", marginRight: "5rem", marginLeft: "5rem"}}
              onChange={(e) => setConfirmPass(e.target.value)}
              variant="standard"
            />
          </div>

          <Button
            variant="contained"
            onClick = {passwordHandler}
            style={{ marginTop: "2rem", marginRight: "5rem", marginLeft: "5rem"  }}
          >
            Submit Password Change
          </Button>

        {/* Success Alert */}
        <Snackbar open={success} onClose={() => setSuccess(false)} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
          <Alert severity="success" sx={{ width: '100%' }} onClose={() => setSuccess(false)}>
            Password Changed Successfully!
          </Alert>
        </Snackbar>

        {/* Failure Alert */}
        <Snackbar open={failure} onClose={() => setFailure(false)} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
          <Alert severity="error" sx={{ width: '100%' }} onClose={() => setFailure(false)}>
            Please check your current password and try again!
          </Alert>
        </Snackbar>

        {/* Failure Alert */}
        <Snackbar open={dontMatch} onClose={() => setDontMatch(false)} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
          <Alert severity="error" sx={{ width: '100%' }} onClose={() => setDontMatch(false)}>
            Passwords don't match, please try again!
          </Alert>
        </Snackbar>
        </div>
      </Box>
    </main>
  );
}