import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './styles/index.css';
import Login from './pages/Login';
import Profile from './pages/Profile';
import ProfileList from './pages/ProfileList';
import reportWebVitals from './reportWebVitals';
import Schedule from './pages/Schedule';
import ContextWrapper from './pages/Schedule/context/ContextWrapper';
import Home from './pages/Home';
import ChangePassword from './pages/ChangePassword';
import PresenterEvents from './pages/PresenterEvents';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}/>
        <Route path="/ProfileList" element={<ProfileList />} />
        <Route path="/Password" element={<ChangePassword />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Schedule" element={
          <ContextWrapper>
            <Schedule />
          </ContextWrapper>
        } />
        <Route path="/Events" element={<PresenterEvents/>}/>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( <App/>  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
