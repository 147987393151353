import UserListTable from "../../Components/UsersTable";
import Navbar from "../../Components/Navbar";
import React, {useEffect } from 'react';
import { useNavigate } from "react-router-dom";

// import cookies 
import Cookies from 'universal-cookie';

function List() {
    // routing
    const navigate = useNavigate();

    useEffect(() => {
    const cookies = new Cookies();
    if(cookies.get('access') === undefined){
        navigate("/");
    }

    }, [navigate]);

    return(
        <div>
            <Navbar/>
            <div style={{margin:"20px"}}>
                <UserListTable data='Placeholder'/>
            </div>
        </div>
        );
}

export default List;
