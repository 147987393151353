import { useState, useEffect } from "react";
import CommentForm from "./CommentForm";
import Comment from "./Comment";
import axios from "axios";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";

var courseId=1;
var userId=1;
const Comments = ({ commentsUrl, currentUserId }) => {
  const [backendComments, setBackendComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);
  const rootComments = backendComments.filter(
    (backendComment) => backendComment.parentId === null
  );
  const navigate = useNavigate();
  const cookies = new Cookies();
  useEffect(() => {
    if(cookies.get('courseId') === undefined || cookies.get('uid') === undefined){
        navigate('/home');
    }
    else{
        courseId = (cookies.get('courseId'));
        userId = (cookies.get('uid'));
    }

}, []);
const headers = {
  'Authorization': 'Bearer ' + cookies.get('access'),
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Accept': "*/*" 
}

  const getReplies = (commentId) =>
    backendComments
      .filter((backendComment) => backendComment.parentId === commentId)
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
  const addComment = async (text, parentId) => {
    const newComment = {
      body: text,
      parentId: parentId,
      userId: userId,
    };
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/comment/${courseId}`, newComment, {headers: headers});
      getComments();
      setActiveComment(null);
    } catch (error) {
      console.error(error);
    }
  };
  

  const updateComment = (text, commentId) => {
    const updatedComment = {
      body: text,
      id: commentId,
    };
    axios
    .patch(`${process.env.REACT_APP_API_URL}/comment/${courseId}`, updatedComment, {headers: headers})
    .then(res => {
      getComments();
      setActiveComment(null);
    });
  };
  const deleteComment = (commentId) => {
    if (window.confirm("Are you sure you want to remove comment?")) {
      const data = {id: commentId}
      axios
      .delete(`${process.env.REACT_APP_API_URL}/comment/${courseId}`, {headers: headers, data: data})
      .then(res => {
        getComments();
        });
    }
  };

  async function getComments(){
    await axios
    .get(`${process.env.REACT_APP_API_URL}/comment/${courseId}`,
        {headers: headers})
    .then(res => {
        setBackendComments(res.data.comments);
    })
}
  useEffect(() => {
    getComments();
  }, []);

  return (
    <div className="comments">
      <h3 className="comments-title">Comments</h3>
      <div className="comment-form-title">Write comment</div>
      <CommentForm submitLabel="Write" handleSubmit={addComment} />
      <div className="comments-container">
        {rootComments.map((rootComment) => (
          <Comment
            key={rootComment.id}
            comment={rootComment}
            replies={getReplies(rootComment.id)}
            activeComment={activeComment}
            setActiveComment={setActiveComment}
            addComment={addComment}
            deleteComment={deleteComment}
            updateComment={updateComment}
            currentUserId={currentUserId}
          />
        ))}
      </div>
    </div>
  );
};

export default Comments;