import React from 'react'

// AdminContext provides the context for the Admin home page
// It is used to store the state of the modal for adding a new class
// It also allows components to share these declared variables.
const AdminContext = React.createContext({
    showClassModal: false,
    setshowClassModal: () => {},
    changes: 0,
    updateChanges: () => {},
    school: "",
    setSchool: () => {},
    grade: "",
    setGrade: () => {},
    classroom: "",
    setClassroom: () => {},
    teacher: [],
    setTeacher: () => {},
    admin: "",
    setAdmin: () => {},
    presenters: [],
    setPresenters: () => {},
    editClass: false,
    setEditClass: () => {},
    editTeacherID: [],
    setEditTeacherID: () => {},
    editClassId: "",
    setEditClassId: () => {},

    // Used for class info modal
    showClassInfoModal: false,
    setShowClassInfoModal: () => {},
    showStudentModal: false,
    setShowStudentModal: () => {},
    showCommentsModal: false,
    setShowCommentsModal: () => {},
    selectedCourseId: "",
    setSelectedCourseId: () => {},
    classInfoSchool: "",
    setclassInfoSchool: () => {},
    classInfoGrade: "",
    setClassInfoGrade: () => {},
    classInfoClassroom: "",
    setClassInfoClassroom: () => {},
    classInfoTeacherId: [],
    setClassInfoTeacherId: () => {},

    // Used for admin checklist and accessibility modal
    showAdminCheckAccModal: false,
    setShowAdminCheckAccModal: () => {},

    // Student add modal
    firstName: "",
    setFirstName: () => {},
    lastName: "",
    setLastName: () => {},
    email: "",
    setEmail: () => {},
    ASN: "",
    setASN: () => {},
    idRelease: false,
    setIdRelease: () => {},
    photos: false,
    setPhotos: () => {},
    videos: false,
    setVideos: () => {},
    writtenWork: false,
    setWrittenWork: () => {},
    voice: false,
    setVoice: () => {},
    research: false,
    setResearch: () => {},
    emailAddress: false,
    setEmailAddress: () => {},
    editStudent: false,
    setEditStudent: () => {},
    selectedStudentID: "",
    setSelectedStudentID: () => {},
});

export default AdminContext