import React, {useContext, useState, useEffect} from 'react'
import { StudentTable } from './items/StudentTable'
import TeacherContext from './context/TeacherContext'
import CreateStudentButton from './items/CreateStudentButton'
import StudentModal from './items/StudentModal'
import TeacherChecklistButton from './items/TeacherChecklistButton'
import TeacherCheckAccModal from './items/TeacherCheckAccModal'
import ImportStudentButton from './items/ImportStudentButton'
import Snackbar from '@mui/material/Snackbar';
import Cookies from 'universal-cookie'
import "./items/style.css";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
//Home page for accounts with teacher role
export const Teacher = () => {
  const cookies = new Cookies();
  const course = cookies.get('course_id')[0];
  const {changes, updateChanges} = useContext(TeacherContext);
  const [uploadError, setUploadError] = useState(false);
  const headers = {
    'Authorization': 'Bearer ' + cookies.get('access'),
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Accept': "*/*" 
  }
  const [file, setFile] = useState();
  const fileReader = new FileReader();

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
    e.target.value=null;
  };

  useEffect(() => {
    // Read file and create student objects for each row
    if (file) {
      fileReader.readAsText(file);
      fileReader.onloadend = () => {
        //const rows = fileReader.result.split("\r\n");
        const rows = fileReader.result.split(/\r?\n/);

        // Create student objects
        const students = [];
        for (let i = 1; i < rows.length; i++) {
          const student = rows[i].split(",");
          // Check if the row is valid
          // Skip if the row is empty
          if (student.length === 1) {
            continue;
          }
          else if (
            student[0] === "" || 
            student[1] === "" || 
            student[0] === undefined || 
            student[1] === undefined ||
            isNaN(student[3])
            ) {
              
            setUploadError(true);
            continue;
          }else{
            students.push({
              firstName: student[0],
              lastName: student[1],
              email: student[2],
              studentnumber: student[3] ? parseInt(student[3]) : 0,
              // If the student has a release, set to true, otherwise false
              idRelease: student[4].toLowerCase() === "yes" ? true : false,
              photos: student[5].toLowerCase() === "yes" ? true : false,
              videos: student[6].toLowerCase() === "yes" ? true : false,
              writtenWork: student[7].toLowerCase() === "yes" ? true : false,
              voice: student[8].toLowerCase() === "yes" ? true : false,
              research: student[9].toLowerCase() === "yes" ? true : false,
              emailAddress: student[10].toLowerCase() === "yes" ? true : false,
            });
          }
        }
        // Post each student to the database
        axios
          .post(`${process.env.REACT_APP_API_URL}/courses/${course}`, students, {headers: headers})
          .then(res => {
              updateChanges(changes+1);
              setFile();
          })
          .catch(err => {
            alert("Error uploading file. Please check the format of your file and ensure no duplicate ASN.");
          }
        );
      };
    }
  }, [file]);



  const {
    showStudentModal,
    showTeacherCheckAccModal,
  } = useContext(TeacherContext);
  useEffect (() => {
  }, [showStudentModal])
  
  return (
    <div style={{margin:"10px"}}>
      {showStudentModal && <StudentModal/>}  
      {showTeacherCheckAccModal && <TeacherCheckAccModal/>}
        <div className="px-2 flex flex-row items-center float-left inline-flex">
          <TeacherChecklistButton/>
        </div>
        <div className="px-2 flex flex-row items-center float-right inline-flex">
          <div className="mr-2">
            <ImportStudentButton/>
            <input id="myFileInput" type={"file"} accept=".csv" onChange={handleFileUpload}/>
          </div>
          <div >
            <CreateStudentButton/>
          </div>
        </div>
      <StudentTable/>
      <Snackbar
              open={uploadError}
              onClose={() => setUploadError(false)}
              autoHideDuration={6000}
            >
              <Alert
                severity="error"
                sx={{ width: "100%" }}
                onClose={() => setUploadError(false)}
              >
                Error uploading file. Please check the format of your file and try again.
              </Alert>
      </Snackbar>
    </div>
    
  )
}
