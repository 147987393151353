import {useContext, useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import { v4 as uuid } from 'uuid';
import axios from "axios";
import Cookies from 'universal-cookie'
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Declare columns that will be shown in the class table
const columns = [
  {
    id: 'school',
    label: 'School',
    minWidth: 150,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'grade',
    label: 'Grade',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'teacher',
    label: 'Teacher',
    minWidth: 150,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'numStudents',
    label: 'Total Students',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'startTime',
    label: 'Presentation Time',
    minWidth: 100,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'Schedule',
    label: 'Schedule',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
];

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          // Increase size of table
          fontSize: '1.1rem',
        },
      },
    },
  },
});

// Function to create the table for presenter classes
export const PresenterClassTable = (props) => {
  const navigate = useNavigate();

  // Declare constants
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const cookies = new Cookies();


  // Get all classes from the database then creates a row for each class
  async function getCourseData(){
    const presenterId = cookies.get('uid');
    const headers = {
      'Authorization': 'Bearer ' + cookies.get('access'),
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Accept': "*/*" 
    }
    await axios
      .get(`${process.env.REACT_APP_API_URL}/presenter/${presenterId}`,
        {headers: headers})
      .then((response) => {
        setRows(createData(response.data));
      })
  }

  const navigateSchedule = (i) =>{
    cookies.set('courseId', i, {path: '/'});
    navigate("/schedule");
  }
  // Creates a row for each class that will be shown in the table
  function createData(data){
    const rows = [];
    // For each array in data.course
    
    data.course.forEach((course) => {
      let startTime = "";
      if (course.arrival_date != null){
        if (course.arrival_date === "Completed"){
          startTime = "Completed";
        }else{
          startTime = course.arrival_date;
          // Convert to Weekday, Month Day
          startTime = new Date(startTime).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', year: 'numeric' });
        }
      }else{
        startTime = "Not Scheduled";
      }
      // Create a row for each class
      rows.push({
        school: course.school,
        grade: course.grade,
        teacher: course.teacher,
        numStudents: course.class_size.toString(),
        startTime: startTime,
        Schedule: 
          <IconButton onClick={() => navigateSchedule(course.id)}>
            <CalendarIcon />          
          </IconButton>
      })
    });
    rows.sort((a, b) => {
      let dateA = a;
      let dateB = b;
      if (a.startTime === "Not Scheduled"){
        dateA = "Monday, Dec 30, 2100";
      }else if (a.startTime === "Completed"){
        dateA = "Monday, Dec 31, 2100";
      }else{
        dateA = a.startTime;
      }
      if (b.startTime === "Not Scheduled"){
        dateB = "Monday, Dec 30, 2100";
      }else if (b.startTime === "Completed"){
        dateB = "Monday, Dec 31, 2100";
      }else{
        dateB = b.startTime;
      }
      dateA = new Date(dateA);
      dateB = new Date(dateB);

      return dateA - dateB;

    });
    return rows;
  }

  // Refreshes the table when changes are made
  useEffect(() => {
    getCourseData();
  },[]); 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div style={{margin: "10px"}}
      data-testid="presenter-class-table-1">      
      <Paper sx={{ width: '100%', overflow: 'hidden' }} >
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
          <ThemeProvider theme={theme}>
            <TableHead>
              <TableRow >
                {columns.map((column) => (
                  <TableCell
                    key = {uuid()}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            </ThemeProvider>
            <TableBody >
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key = {uuid()}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key = {uuid()} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}