import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
// importing services
import { login } from "../../services/Auth";

// import cookies
import Cookies from "universal-cookie";

//import images & styling
import Logo from "../../images/USchool-COLOUR.png"
import "./styles.css";

// MUI Alert Initialization
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Links to UofA websites with more information
function LearnMore(){
  return (
    <div align="center" className="links">
        <a href={"https://www.ualberta.ca/chancellor-and-senate/u-school/index.html"}>
          Learn More
        </a>
        <br/>
        <a href={"https://www.ualberta.ca/chancellor-and-senate/u-school/bring-your-class.html"}>
          Apply
        </a>
        <br/>
        <a href={"https://ualberta.alumniq.com/giving/to/uofafunds"}>
          Donate
        </a>
    </div>
  )
}

// Prints coyright information
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      Uschool {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

// Login page
// Default page for all users
// If user trys to access another page without loging in will be redirected to this page
export default function SignIn() {
  // routing
  const navigate = useNavigate();

  // error state
  const [loginError, setLoginError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const creds = {
      email: data.get("email"),
      password: data.get("password"),
    };
    try {
      const res = await login(creds);
      // If role is teacher and len of course_id array is 0, redirect login and alert user
      console.log(res)
      if (res.role === "TEACHER" && res.course_id.length === 0) {
        alert("You have not been assigned a facilitator yet. Please contact uschool@ualberta.ca for more information.");
        return;
      }
      const cookies = new Cookies();
      cookies.set("access", res.token, {path: '/', sameSite: 'lax'});
      cookies.set("role", res.role, {path: '/', sameSite: 'lax'});
      cookies.set("course_id", res.course_id, {path: '/', sameSite: 'lax'});
      cookies.set("courseId", res.course_id, {path: '/', sameSite: 'lax'});
      cookies.set("uid", res.user_id, {path: '/', sameSite: 'lax'});
      cookies.set("uEmail", creds.email, {path: '/', sameSite: 'lax'});
      
      
      navigate("/home");
    } catch {
      setLoginError(true);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <img src={Logo} alt="UschoolLogo" />
          </div>
          <Typography component="h1" variant="h5" 
          // add top margin
          sx={{mt: 5}}>
          
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              name="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>

            <Snackbar
              open={loginError}
              onClose={() => setLoginError(false)}
              autoHideDuration={6000}
            >
              <Alert
                severity="error"
                sx={{ width: "100%" }}
                onClose={() => setLoginError(false)}
              >
                Incorrect email or password
              </Alert>
            </Snackbar>
          </Box>
        </Box>
        <LearnMore/>
        <Copyright sx={{ mt: 4, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
