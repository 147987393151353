import React, { useState } from "react";

export default function StyledButton({ Icon, label, onClick, testId, color="green", showConfirmationModal="false", confirmModalDescription=""}){
    const [showModal, setShowModal] = useState(false);

    function handleClicked(){
        if (showConfirmationModal){
            setShowModal(true);
        }else{
            onClick();
        }
    }

    function handleConfirmClicked(){
        onClick();
        setShowModal(false);
    }

    
    return (
        <>
            {showModal &&
                <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center"
                    data-testid="button-confirm-modal"
                    style={{ zIndex: '5' }}>
                    <form className="bg-white rounded-lg shadow-2xl w-1/3">
                        <header className="bg-gray-100 px-4 py-2 flex justify-end items-center">
                            <div>
                                <button onClick={() => setShowModal(false)}>
                                    <span className="material-icons-outlined text-gray-400">
                                        close
                                    </span>
                                </button>
                            </div>
                        </header>
                        <div>
                            <div
                                className="pt-3 border-0 
                            text-gray-600 text-l 
                            font-semibold pb-2 
                            w-full 
                            border-gray-200 
                            flex flex-col justify-center items-center" style={{ padding:"1rem 3rem" }}>
                                {confirmModalDescription.split('\\n').map((item, key) => {
                                    return <span key={key}>{item}<br/></span>
                                })}
                            </div>
                            <div className="gSave" style={{ position: "relative", textAlign: "right" }}>
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="bg-red-500 hover:bg-red-600 px-6 py-2 rounded text-white"
                                    style={{ margin: "10px" }}>
                                    No
                                </button>
                                <button type="submit"
                                    onClick={handleConfirmClicked}
                                    className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
                                    style={{ margin: "10px" }}
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            }
            
            <button
                data-testId={testId}
                onClick={handleClicked}
                className={`border p-3 rounded-full flex items-center shadow-lg hover:shadow-2xl`}
            >
                <div className="w-7 h-7">
                    {Icon && <Icon style={{color:`${color}`}}/>}
                </div>
                <span className={`pl-1 pr-7 "text-md" font-semibold text-${color}-900`}>
                    {label}
                </span>
            </button>
        </>
    )
}