import React from 'react'
import {PresenterClassTable} from './items/PresenterClassTable'

// Home page for accounts with presenter role
export const Presenter = () => {
  return (
    <div>
      <PresenterClassTable/>
    </div>
  )
}
