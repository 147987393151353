import React, {useState, useEffect} from 'react'
import TeacherContext from './TeacherContext'

// ContextWrapper helps provide the context for the teacher home page together with TeacherContext
// Variables are declared here and passed to the components that need them
export default function ContextWrapper(props) {
    const [showStudentModal, setShowStudentModal] = useState(false);
    const [changes, updateChanges] = useState(0);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [ASN, setASN] = useState("");
    const [idRelease, setIdRelease] = useState(false);
    const [photos, setPhotos] = useState(false);
    const [videos, setVideos] = useState(false);
    const [writtenWork, setWrittenWork] = useState(false);
    const [voice, setVoice] = useState(false);
    const [research, setResearch] = useState(false);
    const [emailAddress, setEmailAddress] = useState(false);
    const [editStudent, setEditStudent] = useState(false);
    const [selectedStudentID, setSelectedStudentID] = useState("");
    
    const [showTeacherCheckAccModal, setShowTeacherCheckAccModal] = useState(false);

    useEffect(() => {
      // Whenever the add student modal is closed, reset the variables
      if (!showStudentModal) {
        setFirstName("");
        setLastName("");
        setEmail("");
        setASN("");
        setIdRelease(false);
        setPhotos(false);
        setVideos(false);
        setWrittenWork(false);
        setVoice(false);
        setResearch(false);
        setEmailAddress(false);
        setEditStudent(false);
        setSelectedStudentID("");
      }
    }, [showStudentModal]);
  return (
    <TeacherContext.Provider
        value={{
          showStudentModal,
          setShowStudentModal,
          changes,
          updateChanges,
          firstName,
          setFirstName,
          lastName,
          setLastName,
          email,
          setEmail,
          ASN,
          setASN,
          idRelease,
          setIdRelease,
          photos,
          setPhotos,
          videos,
          setVideos,
          writtenWork,
          setWrittenWork,
          voice,
          setVoice,
          research,
          setResearch,
          emailAddress,
          setEmailAddress,
          editStudent,
          setEditStudent,
          selectedStudentID,
          setSelectedStudentID,

          showTeacherCheckAccModal,
          setShowTeacherCheckAccModal,
        }}
        >
        {props.children}
    </TeacherContext.Provider>
  )
}