import * as React from 'react';
import {useEffect} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AccountsDropDown from '../../DropDownMenu/AccountType';
import ActiveDropDown from '../../DropDownMenu/Active';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import axios from "axios";
import Cookies from 'universal-cookie'
export default function EditAccountDialog(props) {
  //set state updater for when dialog is open
  const [open, setOpen] = React.useState(false);
  const [role, setRole] = React.useState(props.user.role);
  const [is_active, setActivity] = React.useState(props.user.is_active);

  //handle when dialog box opened
  const handleClickOpen = () => {
    setOpen(true);
  };
  
  //handle when dialog box closed
  const handleClose = () => {
    setOpen(false);
  };

  //handle when data within fields is changed
  const handleChange = event => {
    props.user[event.target.name] = event.target.value;
  };
    
  //handle role change
  const handleInputRole = (event) => {
    setRole(event.target.value);
  }

  const handleActive = (event) => {
    const active = (event.target.value == "Active" ? true : false);
    setActivity(active);
  }

  //handle when form submitted
  const handleSubmit = async () => {
    setOpen(false);
    const data = props.user;
    await props.editUser(data);
  };
  const cookies = new Cookies();
  const headers = {
    'Authorization': 'Bearer ' + cookies.get('access'),
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Accept': "*/*" 
  }
  const handleResetPassword = (event) => {
    if (window.confirm("Are you sure you want to reset the password? An email will be sent to " + props.user.email + " with a randomly generated password.")){
      console.log(headers);
      axios.patch(`${process.env.REACT_APP_API_URL}/reset_password/${props.user.id}`,null, { headers: headers });
    }
      alert("Email Sent!");
  }
  
  useEffect(() => {
    props.user.is_active = is_active;
  }, [is_active])

  useEffect(() => {
    props.user.role = role;
  }, [role])

  return (
    <div>
      <IconButton onClick={handleClickOpen} aria-label="edit">
          <EditIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} className="form">
        <DialogTitle>Edit Account</DialogTitle>
        <DialogContent style={{ paddingBottom: '8px' }}>
          <TextField className='text'
            autoFocus
            margin="dense"
            name="first_name"
            label="First name"
            defaultValue={props.user.first_name}
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
          <TextField className='text'
            autoFocus
            margin="dense"
            name="last_name"
            label="Last Name"
            defaultValue={props.user.last_name}
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
          <TextField
            autoFocus
            margin='dense'
            name="email"
            label="Email"
            type="email"
            defaultValue={props.user.email}
            fullWidth
            variant="outlined"
            onChange={handleChange}
            />
        <div style={{display : 'flex', alignItems :'left'}}>
            <AccountsDropDown
              role= {props.user.role}
              handleInputRole={handleInputRole}/>
            <ActiveDropDown
              is_active= {props.user.is_active}
              handleActive= {handleActive}/>
        </div>
          <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={handleResetPassword} style={{"color": "red"}}>Reset Password</Button>
            <div>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSubmit}>Save</Button>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}