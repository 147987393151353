import React, {useContext} from 'react'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import GlobalContext from './context/Context';

// Button to create default events
export default function CreateDefaultEventsButton() {
    const { setShowConfirmModal } = useContext(GlobalContext);
    return (
      <button 
        data-testid="create-default-event-button-1"
        onClick={() => {
          setShowConfirmModal(true);
        }}
        className="border p-3 rounded-full flex items-center shadow-lg hover:shadow-2xl"
      >
        <div className="w-7 h-7" >
            <AppRegistrationIcon style={{color: 'green'}}/>
        </div>
        <span className="pl-1 pr-7 text-md font-semibold text-green-900">Create Template</span>
      </button>
    );
  }