import React, { useContext, useState } from 'react'
import AdminContext from './../context/AdminContext'
import axios from "axios";
import "./style.css";
import Cookies from 'universal-cookie'

// StudentModal is the modal that pops up when the user clicks the add student button
export default function StudentModal() {
    const cookies = new Cookies();
    const [errMsg, setErrMsg] = useState("");

    // Get variables from TeacherContext
    const {
        setShowStudentModal,
        changes,
        updateChanges,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        ASN,
        setASN,
        idRelease,
        setIdRelease,
        photos,
        setPhotos,
        videos,
        setVideos,
        writtenWork,
        setWrittenWork,
        voice,
        setVoice,
        research,
        setResearch,
        emailAddress,
        setEmailAddress,
        editStudent,
        selectedStudentID,
        selectedCourseId
    } = useContext(AdminContext);
    const headers = {
        'Authorization': 'Bearer ' + cookies.get('access'),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': "*/*"
    }


    // Adds/Updates a student when the user clicks the save button
    function handleSubmit(e) {
        e.preventDefault();
        // Check if all fields are there
        if (firstName === "" || lastName === ""){
            // If not, display an error message
            setErrMsg("Please fill out first and last names");
        } else if (String(ASN).length !== 9 && String(ASN).length >= 2){
            // If the ASN is not 9 digits, display an error message
            setErrMsg("Please enter a valid ASN");
        } else {
            const course = selectedCourseId
            if (editStudent) {
                // Use a patch request if the user is editing a student
                const newStudent = {
                    "course": course,
                    "firstName": firstName,
                    "lastName": lastName,
                    "email": email,
                    "studentnumber": (String(ASN).length == 9) ? ASN : 0,
                    "idRelease": idRelease,
                    "photos": photos,
                    "videos": videos,
                    "writtenWork": writtenWork,
                    "voice": voice,
                    "research": research,
                    "emailAddress": emailAddress,
                    "id": selectedStudentID
                }
                axios
                    .patch(`${process.env.REACT_APP_API_URL}/courses/${course}`, newStudent,
                        { headers: headers })
                    .then(res => {
                        // Close the modal and refresh the table
                        updateChanges(changes + 1);
                        setShowStudentModal(false);
                    })
                    .catch(err => {
                        // If there is an error, display an error message
                        setErrMsg("ASN already exists in this class.");
                    });
            }
            else {
                // Post the student if the user is adding a new student
                const newStudent = {
                    "course": course,
                    "firstName": firstName,
                    "lastName": lastName,
                    "email":email,
                    "studentnumber": (String(ASN).length == 9) ? ASN : 0,
                    "idRelease": idRelease,
                    "photo": photos,
                    "videos": videos,
                    "writtenWork": writtenWork,
                    "voice": voice,
                    "research": research,
                    "emailAddress": emailAddress,
                };
                axios
                    .post(`${process.env.REACT_APP_API_URL}/courses/${course}`, newStudent,
                        { headers: headers })
                    .then(res => {
                        // Close the modal and refresh the table
                        updateChanges(changes + 1);
                        setShowStudentModal(false);
                    })
                    .catch(err => {
                        // If there is an error, display an error message
                        setErrMsg("ASN already exists in this class.");
                    });

            }
        }
    }

    return (
        <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center"
            data-testid="student-modal-1"
            style={{ zIndex: '5' }}>
            <form className="bg-white rounded-lg shadow-2xl w-1/3" >
                <header className="bg-gray-100 px-4 py-2 flex justify-between items-center">
                    <span className="material-icons-outlined text-gray-400">
                        drag_handle
                    </span>
                    <div>
                        <button onClick={() => setShowStudentModal(false)}>
                            <span className="material-icons-outlined text-gray-400">
                                close
                            </span>
                        </button>
                    </div>
                </header>
                <div style={{ margin: "10px" }}>
                    <div className="First Name">
                        <input
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            value={firstName}
                            required
                            className="pt-3 border-0 
                        text-gray-600 text-l 
                        font-semibold pb-2 
                        w-full border-b-2 
                        border-gray-200 
                        focus:outline-none 
                        focus:ring-0
                        focus:border-blue-500"
                            onChange={(e) => setFirstName(e.target.value)}
                        >
                        </input>
                    </div>
                    <div className="Last Name">
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            value={lastName}
                            required
                            className="pt-3 border-0 
                        text-gray-600 text-l 
                        font-semibold pb-2 
                        w-full border-b-2 
                        border-gray-200 
                        focus:outline-none 
                        focus:ring-0
                        focus:border-blue-500"
                            onChange={(e) => setLastName(e.target.value)}
                        >
                        </input>
                    </div>
                    <div className="email">
                        <input
                            type="text"
                            name="email"
                            placeholder="Email"
                            value={email}
                            className="pt-3 border-0 
                        text-gray-600 text-l 
                        font-semibold pb-2 
                        w-full border-b-2 
                        border-gray-200 
                        focus:outline-none 
                        focus:ring-0
                        focus:border-blue-500"
                            onChange={(e) => setEmail(e.target.value)}
                        >
                        </input>
                    </div>
                    <div className="ASN">
                        <input
                            onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                            pattern="[0-9]*"
                            type="text"
                            name="ASN"
                            placeholder="Alberta Student Number"
                            value={ASN}
                            required
                            className="pt-3 border-0 
                        text-gray-600 text-l 
                        font-semibold pb-2 
                        w-full border-b-2 
                        border-gray-200 
                        focus:outline-none 
                        focus:ring-0
                    focus:border-blue-500"
                            onChange={(e) => setASN(e.target.value)}
                        >
                        </input>
                    </div>
                    <div className="Permissions
                        pt-3 border-0 
                        text-gray-600 text-l 
                        font-semibold pb-2 
                        w-full border-b-2 
                        border-gray-200 
                        focus:outline-none 
                        focus:ring-0
                        focus:border-blue-500">
                        Permissions
                        <div className="idRelease">
                            <input
                                style={{ margin: '5px' }}
                                type="checkbox"
                                defaultChecked={idRelease}
                                className='checkbox-round'
                                name="idRelease"
                                value={idRelease}
                                onChange={(e) => setIdRelease(e.target.checked)}
                            >
                            </input>
                            <label htmlFor="idRelease">
                                ID Release
                            </label>
                        </div>
                        <div className="photos">
                            <input
                                style={{ margin: '5px' }}
                                defaultChecked={photos}
                                type="checkbox"
                                className='checkbox-round'
                                name="photos"
                                value={photos}
                                onChange={(e) => setPhotos(e.target.checked)}
                            >
                            </input>
                            <label htmlFor="photos">
                                Photos
                            </label>
                        </div>
                        <div className="videos">
                            <input
                                style={{ margin: '5px' }}
                                defaultChecked={videos}
                                type="checkbox"
                                className='checkbox-round'
                                name="videos"
                                value={videos}
                                onChange={(e) => setVideos(e.target.checked)}
                            >
                            </input>
                            <label htmlFor="videos">
                                Videos
                            </label>
                        </div>
                        <div className="writtenWork">
                            <input
                                style={{ margin: '5px' }}
                                defaultChecked={writtenWork}
                                type="checkbox"
                                className='checkbox-round'
                                name="writtenWork"
                                value={writtenWork}
                                onChange={(e) => setWrittenWork(e.target.checked)}
                            >
                            </input>
                            <label htmlFor="writtenWork">
                                Written Work
                            </label>
                        </div>
                        <div className="voice">
                            <input
                                style={{ margin: '5px' }}
                                defaultChecked={voice}
                                type="checkbox"
                                className='checkbox-round'
                                name="voice"
                                value={voice}
                                onChange={(e) => setVoice(e.target.checked)}
                            >
                            </input>
                            <label htmlFor="voice">
                                Voice
                            </label>
                        </div>
                        <div className="research">
                            <input
                                style={{ margin: '5px' }}
                                defaultChecked={research}
                                type="checkbox"
                                className='checkbox-round'
                                name="research"
                                value={research}
                                onChange={(e) => setResearch(e.target.checked)}
                            >
                            </input>
                            <label htmlFor="research">
                                Research
                            </label>
                        </div>
                        <div className="emailAddress">
                            <input
                                style={{ margin: '5px' }}
                                defaultChecked={emailAddress}
                                type="checkbox"
                                className='checkbox-round'
                                name="emailAddress"
                                value={emailAddress}
                                onChange={(e) => setEmailAddress(e.target.checked)}
                            >
                            </input>
                            <label htmlFor="emailAddress">
                                Email Address
                            </label>
                        </div>
                    </div>
                    <div className="Save">
                        <button type="submit"
                            onClick={handleSubmit}
                            style={{ margin: '5px', marginBottom: '5px', float: 'right' }}
                            className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
                        >
                            Save
                        </button>
                    </div>
                    <div className="pt-3 border-0 
                        text-gray-600 text-l 
                        font-semibold pb-2 
                        w-full 
                        border-gray-200 
                        focus:outline-none 
                        focus:border-blue-500"
                        style={{ color: "red" }}>
                        {errMsg}
                    </div>
                </div>
            </form>
        </div>
    );
}
