import React, {useContext, useState, useEffect} from 'react'
import AdminContext from './../context/AdminContext'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { v4 as uuid } from 'uuid';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
import "./style.css";
import Cookies from 'universal-cookie'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Declare columns that will be shown in the class table
const columns = [
    {
      id: 'description',
      label: 'Description',
      minWidth: 150,
      align: 'left',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'Delete',
      label: 'Delete',
      minWidth: 10,
      align: 'center',
      format: (value) => value.toFixed(2),
    },
];

const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            // Increase size of table headers
            fontSize: '1.1rem',
          },
        },
      },
    },
  });

// Function for Creating Checklist and Accessibility tables for admin page
export default function AdminCheckAccModal() {

    // Constants for managing accessibility table
    const [rowsAcc, setRowsAcc] = useState([]);
    const [pageAcc, setPageAcc] = useState(0);
    const [rowsPerPageAcc, setRowsPerPageAcc] = useState(100);

    // Constants for managing checklist table
    const [rowsCheck, setRowsCheck] = useState([]);
    const [pageCheck, setPageCheck] = useState(0);
    const [rowsPerPageCheck, setRowsPerPageCheck] = useState(100);

    const [changes, setChanges] = useState(false);
    const [newAcc, setNewAcc] = useState("");
    const [newCheck, setNewCheck] = useState("");

    //Handlers for updating States

    const handleChangePageAcc = (event, newPage) => {
        setPageAcc(newPage);
    };

    const handleChangeRowsPerPageAcc = (event) => {
        setRowsPerPageAcc(+event.target.value);
        setPageAcc(0);
    };

    const handleChangePageCheck = (event, newPage) => {
        setPageCheck(newPage);
    };

    const handleChangeRowsPerPageCheck = (event) => {
        setRowsPerPageCheck(+event.target.value);
        setPageCheck(0);
    };

    // Get cookie and check if user has access to page

    const cookies = new Cookies();

    // Header for axios requests
    const headers = {
      'Authorization': 'Bearer ' + cookies.get('access'),
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Accept': "*/*" 
    }
    
    // When modal is opened, get the accessibility and checklist data from the database
    // and set the rows for the tables

    async function getAccCheckItems(){
        await axios
        .get(`${process.env.REACT_APP_API_URL}/accessibility/`,
            {headers: headers})
        .then((response) => {
            setRowsAcc(createAccData(response.data));
        })

        await axios
        .get(`${process.env.REACT_APP_API_URL}/checklist/`,
            {headers: headers})
        .then((response) => {
            setRowsCheck(createCheckData(response.data));
        })
    }

    // Refreshes the tables when changes are made
    useEffect(() => {
        getAccCheckItems();
    }, [changes]); 
    
    // Get variables from AdminContext
    const {
        setShowAdminCheckAccModal,
    } = useContext(AdminContext);

    function handleSaveCheckList(e){
        e.preventDefault();
    }
    
    // Create update accessibility items in the database
    function handleSaveAccessibility(e){
        e.preventDefault();
        // Post new accessibility item to database
        axios
        .post(`${process.env.REACT_APP_API_URL}/accessibility/`, {
            category: newAcc,
        }, {headers: headers})
        .then((response) => {
            setChanges(!changes);
        })
        // Clear the input field
        setNewAcc("");
    }


    // Delete an accessibility item from the database
    const handleRemoveAcc = (i) => {
        if (window.confirm("Are you sure you want to delete this accessibility item?")) {
            axios
            .delete(`${process.env.REACT_APP_API_URL}/accessibility/`,
              {headers: headers, data: {num: i}})
            .then((response) => {
              setChanges(!changes);
            })
        }
    }


    // Read accessibility data from database and create rows for the table
    function createAccData(data){
        const rows = [];
        // For each item in the accessibility, create a row
        for (let i = 0; i < data.items.length; i++){
          // Add row to the rows array
          rows.push({
            description: data.items[i].category,
            Delete: 
              <IconButton onClick={() => handleRemoveAcc(data.items[i].num)} aria-label="delete">
                <DeleteIcon />
              </IconButton>
          })
        }
        // Sort rows by description
        rows.sort((a, b) => (a.description > b.description) ? 1 : -1)
        return rows;
    }


    // Read checklist data from database and create rows for the table
    function createCheckData(data){
        const rows = [];
        // For each item in the checklist, create a row
        for (let i = 0; i < data.items.length; i++){
            // Add row to the rows array
            rows.push({
                description: data.items[i].text,
                Delete:
                    <IconButton onClick={() => handleRemoveCheck(data.items[i].num)} aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
            })
        }
        // Sort rows by description
        rows.sort((a, b) => (a.description > b.description) ? 1 : -1)
        return rows;
    }


    // Delete a checklist item from the database
    const handleRemoveCheck = (i) => {
        if (window.confirm("Are you sure you want to delete this checklist item?")) {
            axios
            .delete(`${process.env.REACT_APP_API_URL}/checklist/`,
                {headers: headers, data: {num: i}})
            .then((response) => {
                setChanges(!changes);
            })
        }
    }


    // Update checklist items in the database
    function handleSaveCheck(e){
        e.preventDefault();
        // Post new accessibility item to database
        axios
            .post(`${process.env.REACT_APP_API_URL}/checklist/`, { 
                text: newCheck,
            }, {headers: headers})
            .then((response) => {
                setChanges(!changes);
            })
        // Clear the input field
        setNewCheck("");
    }

  return (
    <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center"
        style={{zIndex: '5'}}>
        <div className="bg-white rounded-lg shadow-2xl w-1/2" >
        <header className="bg-gray-100 px-4 py-2 flex justify-between items-center">
                <span className="material-icons-outlined text-gray-400">
                    drag_handle
                </span>
                <div>
                    <button onClick={() => setShowAdminCheckAccModal(false)}>
                        <span className="material-icons-outlined text-gray-400">
                            close
                        </span>
                    </button>
                </div>
            </header>
            <div>
                <div className="px-4 py-2">
                    <Tabs>
                        <TabList>
                            <Tab>Checklist</Tab>
                            <Tab>Accessibility</Tab>
                        </TabList>
                        <TabPanel className="Checklist Tab">
                            <form>
                                <div>
                                    <div style={{margin: "10px"}}>      
                                        <Paper sx={{ width: '100%', overflow: 'hidden' }} >
                                            <TableContainer
                                                sx={{
                                                    border: "4px solid rgba(0,0,0,0)",
                                                    padding:1,
                                                    height: "60vh",
                                                    margin: "auto",
                                                }}
                                            >
                                            <Table stickyHeader aria-label="sticky table">
                                            <ThemeProvider theme={theme}>
                                                <TableHead>
                                                <TableRow >
                                                    {columns.map((column) => (
                                                    <TableCell
                                                        key = {uuid()}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                    ))}
                                                </TableRow>
                                                </TableHead>
                                                </ThemeProvider>
                                                <TableBody >
                                                {rowsCheck
                                                    .slice(pageCheck * rowsPerPageCheck, pageCheck * rowsPerPageCheck + rowsPerPageCheck)
                                                    .map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key = {uuid()}>
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                            <TableCell key = {uuid()} align={column.align}>
                                                                {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}
                                                            </TableCell>
                                                            );
                                                        })}
                                                        </TableRow>
                                                    );
                                                    })}
                                                </TableBody>
                                            </Table>
                                            </TableContainer>
                                            <TablePagination
                                            rowsPerPageOptions={[10, 25, 100]}
                                            component="div"
                                            count={rowsCheck.length}
                                            rowsPerPage={rowsPerPageCheck}
                                            page={pageCheck}
                                            onPageChange={handleChangePageCheck}
                                            onRowsPerPageChange={handleChangeRowsPerPageCheck}
                                            />
                                        </Paper>
                                        <div style={{margin: '10px'}} >
                                            <input
                                                type="text"
                                                name="newCheck"
                                                value={newCheck}
                                                placeholder="New Checklist Item"
                                                className="pt-3 border-0 
                                                text-gray-600 text-l 
                                                font-semibold pb-2                                                 
                                                w-4/5 border-b-2 
                                                border-gray-200 
                                                focus:outline-none 
                                                focus:ring-0
                                                focus:border-blue-500"
                                                autoComplete="off"
                                                onChange={(e) => setNewCheck(e.target.value)} 
                                            /> 
                                            <button type="submit"
                                                    onClick={handleSaveCheck}
                                                    className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
                                                    style={{margin: '5px', marginBottom: '5px', float: 'right'}}>
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </TabPanel>
                        <TabPanel className="Accessibility Tab">
                            <form>
                                <div>
                                    <div style={{margin: "10px"}}>      
                                        <Paper sx={{ width: '100%', overflow: 'hidden' }} >
                                            <TableContainer  
                                                sx={{
                                                    border: "4px solid rgba(0,0,0,0)",
                                                    padding:1,
                                                    height: "60vh",
                                                    margin: "auto",
                                                }}                                 
                                            >
                                            <Table stickyHeader aria-label="sticky table">
                                            <ThemeProvider theme={theme}>
                                                <TableHead>
                                                <TableRow >
                                                    {columns.map((column) => (
                                                    <TableCell
                                                        key = {uuid()}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                    ))}
                                                </TableRow>
                                                </TableHead>
                                                </ThemeProvider>
                                                <TableBody >
                                                {rowsAcc
                                                    .slice(pageAcc * rowsPerPageAcc, pageAcc * rowsPerPageAcc + rowsPerPageAcc)
                                                    .map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key = {uuid()}>
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                            <TableCell key = {uuid()} align={column.align}>
                                                                {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}
                                                            </TableCell>
                                                            );
                                                        })}
                                                        </TableRow>
                                                    );
                                                    })}
                                                </TableBody>
                                            </Table>
                                            </TableContainer>
                                            <TablePagination
                                            rowsPerPageOptions={[10, 25, 100]}
                                            component="div"
                                            count={rowsAcc.length}
                                            rowsPerPage={rowsPerPageAcc}
                                            page={pageAcc}
                                            onPageChange={handleChangePageAcc}
                                            onRowsPerPageChange={handleChangeRowsPerPageAcc}
                                            />
                                        </Paper>
                                        <div style={{margin: '10px'}} >
                                            <input
                                                type="text"
                                                name="newAcc"
                                                value={newAcc}
                                                placeholder="New Accessibility Item"
                                                className="pt-3 border-0 
                                                text-gray-600 text-l 
                                                font-semibold pb-2                                                 
                                                w-4/5 border-b-2 
                                                border-gray-200 
                                                focus:outline-none 
                                                focus:ring-0
                                                focus:border-blue-500"
                                                autoComplete="off"
                                                onChange={(e) => setNewAcc(e.target.value)} 
                                            /> 
                                            <button type="submit"
                                                    onClick={handleSaveAccessibility}
                                                    className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
                                                    style={{margin: '5px', marginBottom: '5px', float: 'right'}}>
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
    </div>
  );
}
