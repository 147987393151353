import {useContext, useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CommentIcon from '@mui/icons-material/Comment';
import { v4 as uuid } from 'uuid';
import axios from "axios";
import Cookies from 'universal-cookie'
import AdminContext from './../context/AdminContext'
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { el } from 'date-fns/locale';

// Declare columns that will be shown in the class table
let sortedColumn = "school";
let sortedOrder = "asc";
const columns = [
  {
    id: 'school',
    label: 'School',
    minWidth: 150,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
    headerCell: (
      <TableCell key="school" align="center">
        School
        <IconButton onClick={() => {return;}}>
          {1 === 'school' && 1 === 'asc' ? (
            <ArrowDropDownIcon />
          ) : (
            <ArrowDropUpIcon />
          )}
        </IconButton>
      </TableCell>
    ),
  },
  {
    id: 'grade',
    label: 'Grade',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'teacher',
    label: 'Teacher',
    minWidth: 200,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'Date',
    label: 'Date',
    minWidth: 30,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'Schedule',
    label: 'Schedule',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'Details',
    label: 'Class Information',
    minWidth: 30,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'Comments',
    label: 'Comments',
    minWidth: 30,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'Edit',
    label: 'Edit',
    minWidth: 10,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'Delete',
    label: 'Del',
    minWidth: 10,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
];


const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          // Increase size of table
          fontSize: '1.3rem',
        },
      },
    },
  },
});


export const ClassTable = (props) => {
  const navigate = useNavigate();

  // Declare constants
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const cookies = new Cookies();
  const headers = {
    'Authorization': 'Bearer ' + cookies.get('access'),
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Accept': "*/*" 
  }

  // Use variables from the AdminContext
  const {
    setGrade,
    setSchool,
    setClassroom,
    setTeacher,
    setAdmin,
    setPresenters,
    changes,
    updateChanges,
    setShowClassModal,
    setEditClass,
    setEditClassId,
    setEditTeacherID,
    setShowClassInfoModal,
    setSelectedCourseId,
    setClassInfoGrade,
    setclassInfoSchool,
    setClassInfoClassroom,
    setClassInfoTeacherId,
    setShowCommentsModal,
} = useContext(AdminContext);


  // Get all classes from the database then creates a row for each class
  async function getCourseData(){
    await axios
      .get(`${process.env.REACT_APP_API_URL}/courses/`,
        {headers: headers})
      .then((response) => {
        setRows(createData(response.data));
      })
  }

  // Refreshes the table when changes are made
  useEffect(() => {
    getCourseData();
  }, [changes]); 

  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Deletes a class from the database when the delete button is clicked
  const handleRemove = (i) => {
    // confirm delete through alert
    if (window.confirm("Are you sure you want to delete this class from " + i.school + "?")) {
      axios
      .delete(`${process.env.REACT_APP_API_URL}/courses/`,
        {headers: headers, data: {id: i.id}})
      .then((response) => {
        updateChanges(changes + 1);
      })
    }


  }
  // Navigate to schedule page
  const navigateSchedule = (i) =>{
    //cookies.set('courseId', i.id, {path: '/'});
    cookies.set('courseId', i.id, {path: '/', sameSite: 'lax'});
    navigate("/schedule");
  }

  // Opens the edit class modal when the edit button is clicked
  const handleEdit = (i) => {
    setGrade(i.grade);
    setSchool(i.school);
    setClassroom(i.classroom);
    setEditTeacherID(i.teacher);
    setEditClassId(i.id);
    axios.get(`${process.env.REACT_APP_API_URL}/courses/`,
      {headers: headers})
      .then((response) => {
        // Get presenters email whose id is in i.presenters
        let presenters = [];
        for (let j = 0; j < i.presenters.length; j++){
          presenters.push((response.data.presenters.find((p) => p.id === i.presenters[j])).email);
        }
        setPresenters(presenters);

        let teacher = [];
        for (let j = 0; j < i.teacher.length; j++){
          teacher.push((response.data.teachers.find((p) => p.id === i.teacher[j])).email);
        }
        setTeacher(teacher);
        setAdmin((response.data.admins.find((a) => a.id === i.admin)).email);
      });
    setEditClass(true);
    setShowClassModal(true);
    updateChanges(changes + 1);
  }

  // Shows information of class and students in the class
  const checkDetails = (i) => {
    setClassInfoGrade(i.grade);
    setclassInfoSchool(i.school);
    setClassInfoClassroom(i.classroom);
    setSelectedCourseId(i.id);
    setClassInfoTeacherId(i.teacher);
    setShowClassInfoModal(true);
    
  }
  const checkComments = (i) => {
    setSelectedCourseId(i.id);
    setShowCommentsModal(true);
    
  }
  // Creates a row for each class that will be shown in the table
  function createData(data){
    const rows = [];
    var teacherName = "";
    var currentTeacher = "";
    for (let i = 0; i < data.sets.length; i++){
      let classTeachers = "";
      for (let tid of data.sets[i].teacher){
        currentTeacher = data.teachers.find((t) => t.id === tid);
        if (currentTeacher !== undefined){
          teacherName = "";
          if (currentTeacher.first_name !== null){
            teacherName += currentTeacher.first_name + " ";
          }
          if (currentTeacher.last_name !== null){
            teacherName += currentTeacher.last_name + " ";
          }
          if (currentTeacher.email !== null){
            teacherName += "(" + currentTeacher.email + ")";
          }  
        }
        classTeachers += teacherName + '\n';
      }
      let date = data.sets[i].arrival_date;
      if (data.sets[i].arrival_date != "Completed" && data.sets[i].arrival_date != "Not Scheduled"){
        date = new Date(data.sets[i].arrival_date).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric'});
      }
      classTeachers = classTeachers.split('\n');
      classTeachers = classTeachers.map((line)=><div key={uuid()}>{line}</div>);
      // For each class in the database, create a row
      rows.push({
        id: uuid(),
        school: data.sets[i].school,
        grade: data.sets[i].grade,
        teacher: classTeachers,
        Date: date,
        Schedule: (data.sets[i].filled) ? (
          <IconButton onClick={() => navigateSchedule(data.sets[i])}>
            <EventAvailableIcon style={{color: "green"}}/>          
          </IconButton>
          ) : (
            <IconButton onClick={() => navigateSchedule(data.sets[i])}>
              <CalendarIcon />          
            </IconButton>
          ),
        Details: 
          <IconButton onClick={() => checkDetails(data.sets[i])} aria-label="details">
            <InfoIcon />
          </IconButton>,
        Comments:
          <IconButton onClick={() => checkComments(data.sets[i])} aria-label="comments">
            <CommentIcon />
          </IconButton>,
        Edit: 
          <IconButton onClick={() => handleEdit(data.sets[i])} aria-label="edit">
            <EditIcon />
          </IconButton>,
        Delete: 
          <IconButton onClick={() => handleRemove(data.sets[i])} aria-label="delete">
            <DeleteIcon />
          </IconButton>
      })
    }


    //rows.sort((a, b) => (a.school > b.school) ? 1 : -1);

    /*
    Date > Not Scheduled > Completed
    */
    if (sortedColumn === 'school'){
      if(sortedOrder === "asc"){
        rows.sort((a, b) => (a.school > b.school) ? 1 : -1);
      }
      else{
        rows.sort((a, b) => (a.school < b.school) ? 1 : -1);
      }
    }
    else if (sortedColumn === 'Date'){
      rows.sort((a, b) => {
        let dateA = a;
        let dateB = b;
        if (a.Date === "Not Scheduled"){
          dateA = "Monday, Dec 30, 2100";
        }else if (a.Date === "Completed"){
          dateA = "Monday, Dec 31, 2100";
        }else{
          dateA = a.Date;
        }
        if (b.Date === "Not Scheduled"){
          dateB = "Monday, Dec 30, 2100";
        }else if (b.Date === "Completed"){
          dateB = "Monday, Dec 31, 2100";
        }else{
          dateB = b.Date;
        }
  
        // Sort by school name if equal
        if (dateA === dateB){
          if(sortedOrder === "asc"){
            rows.sort((a, b) => (a.school > b.school) ? 1 : -1);
          }
          else{
            rows.sort((a, b) => (a.school < b.school) ? 1 : -1);
          }
        }
        dateA = new Date(dateA);
        dateB = new Date(dateB);
        if (sortedOrder === "asc"){
          return dateA - dateB;
        }else{
          return dateB - dateA;
        }
      });
    }
    
    return rows;
  }

  return (
    <div style={{margin: "10px"}}>      
      <Paper sx={{ width: '100%', overflow: 'hidden' }} >
        <TableContainer
            sx={{
              border: "4px solid rgba(0,0,0,0)",
              padding:1,
              margin: "auto",
          }}
        >
          <Table stickyHeader aria-label="sticky table">
          <ThemeProvider theme={theme}>
          <TableHead>
            <TableRow key={uuid()}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.id === 'school' || column.id === 'Date' ? (
                    <div>
                      {column.label}
                      <IconButton
                        onClick={() => {
                          sortedColumn = column.id;
                          if (sortedOrder === "asc"){
                            sortedOrder = "desc";
                          }else{
                            sortedOrder = "asc";
                          }
                          updateChanges(changes + 1); 
                        }           
                      }
                  
                      >
                        {column.id === 'school' && 1 === 'asc' ? (
                          <ArrowDropDownIcon />
                        ) : (
                          <ArrowDropUpIcon />
                        )}
                      </IconButton>
                    </div>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
            </ThemeProvider>
            <TableBody>
              {rows && rows.length > 0 ? (
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return(
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={`${row.id}-${column.id}`} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows ? rows.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
