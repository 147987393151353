import axios from "axios";

// import cookies 
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// get profile endpoint
const getProfile = () =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/profile/${cookies.get('uid')}`, {
      headers: {
        'Authorization': 'Bearer ' + cookies.get('access'),
        'Access-Control-Allow-Origin': '*',
        'Accept':"*/*"
      }
    })
    .then((response) => {
      return response.data.data;
    });

// edit profile endpoint
const editProfile = (data) =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/profile/${cookies.get('uid')}`,data, {
      headers: {
        'Authorization': 'Bearer ' + cookies.get('access'),
        'Access-Control-Allow-Origin': '*',
        'Accept':"*/*"
      }
    })
    .then((response) => {
      return response.data;
    });

// change user's password
const changePassword = (data) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/change_password/`,data, {
      headers: {
        'Authorization': 'Bearer ' + cookies.get('access'),
        'Access-Control-Allow-Origin': '*',
        'Accept':"*/*"
      }
    })
    .then((response) => {
      return response.data;
    });

export { getProfile, editProfile, changePassword };