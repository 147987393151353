import React, {useContext} from 'react'
import plusImg from './assets/plus.svg'
import GlobalContext from './context/Context';

// Button to open the event modal where the user can create a new event
export default function CreateEventButton() {
    const { showEventModal, setShowEventModal } = useContext(GlobalContext);
    return (
      <button 
        data-testid="create-event-button-1"
        onClick={() => {
          setShowEventModal(true);
        }}
        className="border p-3 rounded-full flex items-center shadow-lg hover:shadow-2xl"
      >
        <img src={plusImg} alt="create_event" className="w-7 h-7" />
        <span className="pl-1 pr-7 text-md font-semibold text-green-900">New Event</span>
      </button>
    );
  }