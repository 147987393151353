import React, {useState, useEffect} from 'react'
import AdminContext from './AdminContext'

// AdminWrapper helps provide the context for the Admin home page together with AdminContext
// Variables are declared here and passed to the components that need them
export default function AdminWrapper(props) {
    const [showClassModal, setShowClassModal] = useState(false);
    const [showCommentsModal, setShowCommentsModal] = useState(false);
    const [changes, updateChanges] = useState(0);
    const [school, setSchool] = useState("");
    const [grade, setGrade] = useState("");
    const [classroom, setClassroom] = useState("");
    const [teacher, setTeacher] = useState([]);
    const [admin, setAdmin] = useState("");
    const [presenters, setPresenters] = useState([]);
    const [editClass, setEditClass] = useState(false);
    const [editTeacherID, setEditTeacherID] = useState([]);
    const [editClassId, setEditClassId] = useState("");
    const [showStudentModal, setShowStudentModal] = useState(false);

    // Used for class info modal
    const [showClassInfoModal, setShowClassInfoModal] = useState(false);
    const [selectedCourseId, setSelectedCourseId] = useState("");
    const [classInfoSchool, setclassInfoSchool] = useState("");
    const [classInfoGrade, setClassInfoGrade] = useState("");
    const [classInfoClassroom, setClassInfoClassroom] = useState("");
    const [classInfoTeacherId, setClassInfoTeacherId] = useState([]);

    // Used for admin checklist and accessibility modal
    const [showAdminCheckAccModal, setShowAdminCheckAccModal] = useState(false);

    // Student add modal
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [ASN, setASN] = useState("");
    const [idRelease, setIdRelease] = useState(false);
    const [photos, setPhotos] = useState(false);
    const [videos, setVideos] = useState(false);
    const [writtenWork, setWrittenWork] = useState(false);
    const [voice, setVoice] = useState(false);
    const [research, setResearch] = useState(false);
    const [emailAddress, setEmailAddress] = useState(false);
    const [editStudent, setEditStudent] = useState(false);
    const [selectedStudentID, setSelectedStudentID] = useState("");

  useEffect(() => {
    // Whenever the add class modal is closed, reset the variables
    if(!showClassModal){
      setSchool("");
      setGrade("");
      setClassroom("");
      setTeacher([]);
      setEditClass(false);
      setAdmin("");
      setPresenters([]);
    }
  }, [showClassModal]);
  useEffect(() => {
    // Whenever the add student modal is closed, reset the variables
    if (!showStudentModal) {
      setFirstName("");
      setLastName("");
      setEmail("");
      setASN("");
      setIdRelease(false);
      setPhotos(false);
      setVideos(false);
      setWrittenWork(false);
      setVoice(false);
      setResearch(false);
      setEmailAddress(false);
      setEditStudent(false);
      setSelectedStudentID("");
    }
  }, [showStudentModal]);
  useEffect(()  => {
    if(!showClassInfoModal){
      setSelectedCourseId("");
      setclassInfoSchool("");
      setClassInfoGrade("");
      setClassInfoClassroom("");
      setClassInfoTeacherId([]);
    }
  }, [showClassInfoModal]);
  return (
    <AdminContext.Provider
        value={{
            showClassModal,
            setShowClassModal,
            changes,
            updateChanges,
            school,
            setSchool,
            grade,
            setGrade,
            classroom,
            setClassroom,
            teacher,
            setTeacher,
            admin,
            setAdmin,
            presenters,
            setPresenters,
            editClass,
            setEditClass,
            editTeacherID,
            setEditTeacherID,
            editClassId,
            setEditClassId,
            showClassInfoModal,
            setShowClassInfoModal,
            showCommentsModal,
            setShowCommentsModal,
            selectedCourseId,
            setSelectedCourseId,
            classInfoSchool,
            setclassInfoSchool,
            classInfoGrade,
            setClassInfoGrade,
            classInfoClassroom,
            setClassInfoClassroom,
            classInfoTeacherId,
            setClassInfoTeacherId,
            showAdminCheckAccModal,
            setShowAdminCheckAccModal,
            showStudentModal,
            setShowStudentModal,

            // Student add modal
            firstName,
            setFirstName,
            lastName,
            setLastName,
            email,
            setEmail,
            ASN,
            setASN,
            idRelease,
            setIdRelease,
            photos,
            setPhotos,
            videos,
            setVideos,
            writtenWork,
            setWrittenWork,
            voice,
            setVoice,
            research,
            setResearch,
            emailAddress,
            setEmailAddress,
            editStudent,
            setEditStudent,
            selectedStudentID,
            setSelectedStudentID,
        }}
        >
        {props.children}
    </AdminContext.Provider>
  )
}
