import React, {useContext} from 'react'
import TeacherContext from './../context/TeacherContext';
import RuleIcon from '@mui/icons-material/Rule';

// This is the button to check/view the teacher's checklist and accessibility
export default function TeacherChecklistButton() {
    const {setShowTeacherCheckAccModal} = useContext(TeacherContext);
    return (
      <button
        data-testid="teacher-checklist-button-1"
        onClick={() => {
            setShowTeacherCheckAccModal(true);
        }}
        className="border p-4 rounded-full flex items-center shadow-xl hover:shadow-2xl"
      >
        <div className="w-7 h-7" >
            <RuleIcon style={{color: 'green'}}/>
        </div>
        <span className="pl-1 pr-7 text-xl font-semibold text-green-900">Checklist and Accessibility</span>
      </button>
    );
  }