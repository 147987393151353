import {useContext, useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuid } from 'uuid';
import axios from "axios";
import Cookies from 'universal-cookie'
import TeacherContext from './../context/TeacherContext'
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Declare columns that will be shown in the student table
const columns = [
  { 
    id: 'nameFirst', 
    label: 'First Name', 
    align: 'left',
    minWidth: 100 
  },
  { 
    id: 'nameLast', 
    label: 'Last Name', 
    align: 'left',
    minWidth: 100 
  },
  {
    id: 'email',
    label: 'Email',
    align: 'left',
    minWidth: 100
  },
  {
    id: 'asn',
    label: 'ASN',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'id_release',
    label: 'ID Release',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'photos',
    label: 'Photos',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'video',
    label: 'Video',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'written_works',
    label: 'Written Works',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'voice',
    label: 'Voice',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'research',
    label: 'Research',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'emailAddress',
    label: 'Email Address',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'Edit',
    label: '',
    minWidth: 25,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'Delete',
    label: '',
    minWidth: 25,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
];

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          // Increase size of table
          fontSize: '1.1rem',
        },
      },
    },
  },
});

// Function for creating table of students
export const StudentTable = (props) =>{
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  // Use variables from TeacherContext
  const{
    changes,
    updateChanges,
    setFirstName,
    setLastName,
    setEmail,
    setASN,
    setIdRelease,
    setPhotos,
    setVideos,
    setWrittenWork,
    setVoice,
    setResearch,
    setEmailAddress,
    setEditStudent,
    setShowStudentModal,
    setSelectedStudentID
  } = useContext(TeacherContext)
  const cookies = new Cookies();
  const course = cookies.get('course_id');

  // Header for axios requests
  const headers = {
    'Authorization': 'Bearer ' + cookies.get('access'),
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Accept': "*/*" 
  }
  
  // Get students from database to populate table
  async function getStudentData() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/courses/${course}`,
          {headers: headers})
      .then(res => {
        setRows(createData(res.data['students']));
      });
  }

  // Refresh students in the table when changes are made
  useEffect(() => {
    getStudentData();
  }, [changes]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Remove student from database of the selected row
  const handleRemove = (i) => {
    if (window.confirm("Are you sure you want to delete " + i.firstName + " " + i.lastName + "?")) {
      axios
      .delete(`${process.env.REACT_APP_API_URL}/courses/${i.course}`,
          {headers: headers, data: {id: i.id}})
      .then(res => {
        updateChanges(changes+1);
      });
    }
  }

  // Edit student from database of the selected row
  // Opens the student modal where edits can be made
  const handleEdit = (i) => {
    setFirstName(i.firstName);
    setLastName(i.lastName);
    setEmail(i.email);
    setASN(i.studentnumber);
    setIdRelease(i.idRelease);
    setPhotos(i.photos);
    setVideos(i.videos);
    setWrittenWork(i.writtenWork);
    setVoice(i.voice);
    setResearch(i.research);
    setEmailAddress(i.emailAddress);
    setSelectedStudentID(i.id);
    setEditStudent(true);
    setShowStudentModal(true);
  }

  // Add students into rows to be shown in the table
  function createData(data) {
    const rows = [];
    var cId_release = "";
    var cPhotos = "";
    var cVideo = "";
    var cWritten_works = "";
    var cVoice = "";
    var cResearch = "";
    var cEmailAddress = "";

    // Loop through each student and add them to the rows
    // For each boolean field, loop through to show either a check or a cross
    for (let i = 0; i < data.length; i++) {
      if (data[i].idRelease === true) {
        cId_release = <CheckIcon style={{ color: 'green'}}/>;
      } else {
        cId_release = <CloseIcon style={{ color: 'red'}}/>;
      }
      if (data[i].photos === true) {
        cPhotos = <CheckIcon style={{ color: 'green'}}/>;
      } else {
        cPhotos = <CloseIcon style={{ color: 'red'}}/>;
      }
      if (data[i].videos === true) {
        cVideo = <CheckIcon style={{ color: 'green'}}/>;
      } else {
        cVideo = <CloseIcon style={{ color: 'red'}}/>;
      }
      if (data[i].writtenWork === true) {
        cWritten_works = <CheckIcon style={{ color: 'green'}}/>;
      } else {
        cWritten_works = <CloseIcon style={{ color: 'red'}}/>;
      }
      if (data[i].voice === true) {
        cVoice = <CheckIcon style={{ color: 'green'}}/>;
      } else {
        cVoice = <CloseIcon style={{ color: 'red'}}/>;
      }
      if (data[i].research === true) {
        cResearch = <CheckIcon style={{ color: 'green'}}/>;
      } else {
        cResearch = <CloseIcon style={{ color: 'red'}}/>;
      }
      if (data[i].emailAddress === true) {
        cEmailAddress = <CheckIcon style={{ color: 'green'}}/>;
      } else {
        cEmailAddress = <CloseIcon style={{ color: 'red'}}/>;
      }
      rows.push({
        nameFirst: data[i].firstName,
        nameLast: data[i].lastName,
        email: data[i].email,
        asn: String(data[i].studentnumber),
        id_release: cId_release,
        photos: cPhotos,
        video: cVideo,
        written_works: cWritten_works,
        voice: cVoice,
        research: cResearch,
        emailAddress: cEmailAddress,
        Edit: 
          <IconButton onClick={() => handleEdit(data[i])} aria-label="edit">
            <EditIcon />
          </IconButton>,
        Delete:
          <IconButton onClick={() => handleRemove(data[i])} aria-label="delete">
            <DeleteIcon />
          </IconButton>
      });
    }
    // Sort the rows by student first name
    rows.sort((a, b) => (a.nameFirst > b.nameFirst) ? 1 : -1);
    return rows;
  }

  return (
    <div style={{margin: "10px"}}>      
      <Paper sx={{ width: '100%', overflow: 'hidden' }} >
        <TableContainer
          sx={{
            border: "4px solid rgba(0,0,0,0)",
            padding:1,
            height: "80vh",
            margin: "auto",
          }}>
          <Table stickyHeader aria-label="sticky table">
          <ThemeProvider theme={theme}>
            <TableHead>
              <TableRow >
                {columns.map((column) => (
                  <TableCell
                    key = {uuid()}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            </ThemeProvider>
            <TableBody >
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key = {uuid()}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key = {uuid()} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
