import * as React from 'react';
import { useNavigate } from "react-router-dom";
import "./style.css";
// import cookies 
import Cookies from 'universal-cookie';
import WhiteOutlineLogo from "../../images/USchool-COLOUR-REVERSE.png"
import HelpIcon from '@mui/icons-material/Help';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';


export default function Navbar(){
    // routing
    const cookies = new Cookies();
    const role = cookies.get('role');
    const navigate = useNavigate();

    // logout function
    const logout = () =>{
        cookies.remove('access', { path: '/' });
        cookies.remove('role', { path: '/' });
        navigate("/")
    }
    
    // navigate to users table 
    const navigateManage = () =>{
        navigate("/profilelist")
    }

    //Navigate to home
    const navigateHome = () =>{
        navigate("/home")
    }

    //Navigate to profile
    const navigateProfile = () =>{
        navigate("/profile")
    }

    //Navigate to schedule
    const navigateSchedule = () =>{
        navigate("/schedule")
    }

    const navigateEvents = () =>{
        navigate("/events")
    }
    //Navigate to Change Password
    const navigatePassword = () =>{
        navigate("/password")
    }
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }}
            //White arrow
            arrow
        />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 500,
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid #dadde9',
        },
      }));
    return (
    <nav className="nav">
        <ul className='home'>
            <li id="home">
                <button onClick={navigateHome}>
                    <img style={{height:'35px'}} object-fit="cover" src={WhiteOutlineLogo} onClick={navigateHome} alt="USchoolLogo"/>
                </button>
            </li>
            <li id="home">
                <button onClick={navigateHome}>Home</button>
            </li>
            <li id="profile">
                <button onClick={navigateProfile}>My Profile</button>
            </li>
            {role === 'ADMIN' ? 
                <li id="accounts">
                    <button onClick={navigateManage}>Manage Accounts</button>
                </li>: ""
            }
            {role === 'ADMIN' ? 
                <li id="events">
                    <button onClick={navigateEvents}>Presenter Events</button>
                </li>: ""
            }
            {role === 'TEACHER' ?
                <li id="schedule">
                    <button onClick={navigateSchedule}>Schedule</button>
                </li> : ""
            }
        </ul>
        <ul className="manage">
            <li id="help">
                <button>
                <HtmlTooltip 
                    title={
                        <React.Fragment>
                            <Typography color="inherit"
                            style={{ marginTop: '5px',
                            marginBottom: '5px',
                            marginLeft: '5px',
                            marginRight: '5px',fontSize:'20px', fontWeight:'bold', fontFamily:'Roboto'}}
                            >Help</Typography>
                            <p 
                                style={{
                                    fontSize: '17px',
                                    color: 'black',
                                    textAlign: 'left',
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                }}
                            >
                                For any questions or concerns, please contact us at 
                                <br></br>
                                <a href="mailto:uschool@ualberta.ca ">uschool@ualberta.ca</a>
                            </p>
                        </React.Fragment>
                    }
                    placement="bottom"
                    arrow
                    >
                    <HelpIcon/>
                </HtmlTooltip>
                </button>
            </li>
            <li id= "password">
                <button onClick={navigatePassword}>Change Password</button>
            </li>
            <li id= "logout">
                <button onClick={logout}>Sign Out</button>
            </li>
        </ul>
    </nav>
    )
}