import React from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import axios from "axios";
import Cookies from 'universal-cookie'

// Button to import students from a CSV file
export default function ExportClassButton() {
    const cookies = new Cookies();

    // Header for axios requests
    const headers = {
      'Authorization': 'Bearer ' + cookies.get('access'),
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Accept': "*/*" 
    }
    return (
      <button
        data-testid="export-class-button-1"
        onClick={() => {
            axios.get(`${process.env.REACT_APP_API_URL}/export/`, {headers: headers})
            .then((response) => {
                // Download a csv file with the data
                const csvLink = document.createElement('a');
                csvLink.href = window.URL.createObjectURL(new Blob([response.data], {type: 'text/csv'}));
                // Download csv with name students + course id
                csvLink.setAttribute('download', `class.csv`);
                document.body.appendChild(csvLink);
                csvLink.click();
                document.body.removeChild(csvLink);
            })
        }}
        className="border p-4 rounded-full flex items-center shadow-xl hover:shadow-2xl"
      >
        <div className="w-7 h-7" >
            <FileDownloadIcon style={{color: 'green'}}/>
        </div>
        <span className="pl-1 pr-7 text-xl font-semibold text-green-900">Download Class Data</span>
      </button>
    );
  }