import Navbar from "../../Components/Navbar"
import Cookies from 'universal-cookie';
import { Admin } from './components/Admin';
import { Teacher } from './components/Teacher';
import { Presenter } from './components/Presenter';
import TeacherWrapper from "./components/context/TeacherWrapper";
import AdminWrapper from "./components/context/AdminWrapper";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
// Checks what a users role is and sends them to the correct home page for their role
export default function Home() {
    const cookies = new Cookies();
    const role = cookies.get('role');
    
    const navigate = useNavigate();

    useEffect(() => {
    const cookies = new Cookies();
    if(cookies.get('access') === undefined){
        navigate("/");
    }

    }, [navigate]);
    return (
        <div name = 'homepage'>
            <Navbar />
            {role === 'ADMIN' 
                ? <AdminWrapper>
                    <Admin /> 
                  </AdminWrapper>
                : role === 'TEACHER' 
                ? <TeacherWrapper>
                    <Teacher />
                  </TeacherWrapper>
                : role === 'PRESENTER' 
                ? <Presenter /> : ""}
        </div>
    );
}