import React, {useContext, useState, useEffect} from 'react'
import GlobalContext from './context/Context'
import 'react-datepicker/dist/react-datepicker.css'
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import axios from "axios";


// Course id of the current schedule
var courseId = '1';

// Ask the user to confirm the creation of the default events
export default function DeleteAllModal() {
    // Get variables from the global context
    const cookies = new Cookies();
    const navigate = useNavigate();
    const headers = {
        'Authorization': 'Bearer ' + cookies.get('access'),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': "*/*" 
    }
    const {
        setShowDeleteModal,
        changes,
        updateChanges,
    } = useContext(GlobalContext);

    // Go home if course is not selected
    useEffect(() => {
        if(cookies.get('courseId') === undefined){
            navigate('/home');
        }
        else{
            courseId = (cookies.get('courseId'));
        }
    }, [])

    // Post all default events when the user confirms the default schedule
    function handleSubmit(e) {
        e.preventDefault()
        // delete all events at /schedule/{courseId}
        const payload = {
            deleteAll: true
        };
        axios.delete(`${process.env.REACT_APP_API_URL}/schedule/${courseId}`, { headers: headers, data: payload })
        .then(res => {
            // Close the modal and refresh the table
            updateChanges(changes + 1);
            setShowDeleteModal(false);
        });
    }
  
    return (
    <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center"
        data-testid="confirm-modal-1"
        style={{zIndex: '5'}}>
        <form className="bg-white rounded-lg shadow-2xl w-1/3">
            <header className="bg-gray-100 px-4 py-2 flex justify-end items-center">
                <div>
                    <button onClick={() => setShowDeleteModal(false)}>
                        <span className="material-icons-outlined text-gray-400">
                            close
                        </span>
                    </button>
                </div>
            </header>
            <div>
                <div
                    className="pt-3 border-0 
                            text-gray-600 text-l 
                            font-semibold pb-2 
                            w-full 
                            border-gray-200 
                            flex justify-center items-center">
                    Would you like to delete all events in this schedule? <br></br>
                </div>
                <div className="gSave" style={{position: "relative", textAlign:"right"}}>
                    <button 
                        onClick={() => setShowDeleteModal(false)}  
                        className="bg-red-500 hover:bg-red-600 px-6 py-2 rounded text-white" 
                        style={{margin: "10px"}}>
                        No
                    </button>
                    <button type="submit" 
                            onClick={handleSubmit}
                            className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
                            style={{margin: "10px"}}
                            >
                        Yes
                    </button>
                </div>
            </div>
        </form>
    </div>
  );
}
