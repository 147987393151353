import React, {useContext} from 'react'
import plusImg from './../assets/plus.svg'
import TeacherContext from './../context/TeacherContext';

// Button to add a new student
// When clicked, it opens the modal to add a new student
export default function CreateStudentButton() {
    const { showStudentModal, setShowStudentModal } = useContext(TeacherContext);
    return (
      <button
        data-testid="create-student-button-1"
        onClick={() => {
          setShowStudentModal(true);
        }}
        className="border p-4 rounded-full flex items-center shadow-xl hover:shadow-2xl"
      >
        <img src={plusImg} alt="create_event" className="w-7 h-7" />
        <span className="pl-1 pr-7 text-xl font-semibold text-green-900">Add Student</span>
      </button>
    );
  }