import React, {useContext, useState, useEffect} from 'react'
import TeacherContext from './../context/TeacherContext'
import axios from "axios";
import "./style.css";
import Cookies from 'universal-cookie'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { v4 as uuid } from 'uuid';
import { ThemeProvider, createTheme } from '@mui/material/styles';


// Declare columns that will be shown in the accessibility table
const columnsAcc = [
    {
        id: 'description',
        label: 'Description',
        width: '60%',
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'isPresent',
        label: 'Present in Class',
        width: '10%',
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'Comments',
        label: 'Comments',
        width: '30%',
        align: 'center',
        format: (value) => value.toFixed(2),
    }
];

const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            // Increase size of table headers
            fontSize: '1.1rem',
          },
        },
      },
    },
  });

// Declare columns that will be shown in the checklist table
const columnsCheck = [
    {
        id: 'description',
        label: 'Description',
        // absolute width 20%
        width: '60%',
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'isPresent',
        label: 'Done',
        width: '10%',
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'Comments',
        label: 'Comments',
        width: '30%',
        align: 'center',
        format: (value) => value.toFixed(2),
    }
];

// Variables for storing data entered by the user
var accList = [];
var checkList = [];

// Function for creating checklist and accesiblity tables for teacher home page
export default function TeacherCheckAccModal() {
    // Constants for managing accessibility table
    const [rowsAcc, setRowsAcc] = useState([]);
    const [pageAcc, setPageAcc] = useState(0);
    const [rowsPerPageAcc, setRowsPerPageAcc] = useState(100);
    const [rowsCheck, setRowsCheck] = useState([]);
    const [pageCheck, setPageCheck] = useState(0);
    const [rowsPerPageCheck, setRowsPerPageCheck] = useState(100);
    const [newCheck, setNewCheck] = useState("");

    // Handlers to change states
    const handleChangePageAcc = (event, newPage) => {
        setPageAcc(newPage);
    };

    const handleChangeRowsPerPageAcc = (event) => {
        setRowsPerPageAcc(+event.target.value);
        setPageAcc(0);
    };

    const handleChangePageCheck = (event, newPage) => {
        setPageCheck(newPage);
    };

    const handleChangeRowsPerPageCheck = (event) => {
        setRowsPerPageCheck(+event.target.value);
        setPageCheck(0);
    };


    // Header for making requests
    const cookies = new Cookies();
    const headers = {
      'Authorization': 'Bearer ' + cookies.get('access'),
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Accept': "*/*" 
    }

    // Get the teacher's checklist and accessibility data and load them into table
    async function getAccCheckItems(){
        // The teacher's class id
        let classId = cookies.get('courseId')[0];
        // Retrieve accessibility data
        await axios
        .get(`${process.env.REACT_APP_API_URL}/accessibility/${classId}`,
            {headers: headers})
        .then((response) => {
            setRowsAcc(createAccData(response.data));
            accList = response.data.items;
        })

        // Retrieve checklist data
        await axios
        .get(`${process.env.REACT_APP_API_URL}/checklist/${classId}`,
            {headers: headers})
        .then((response) => {
            setRowsCheck(createCheckData(response.data));
            checkList = response.data.items;
        })
    }

    // Retrieve the teacher's checklist and accessibility data when the modal is opened
    useEffect(() => {
        getAccCheckItems();
    }, []); 

    // Get variables from TeacherContext
    const {
        setShowTeacherCheckAccModal,
    } = useContext(TeacherContext);

    // When the user submits the form, update the checklist database and close the modal 
    function handleSaveCheckList(e){
        e.preventDefault();
        // The teacher's class id
        let classId = cookies.get('courseId')[0];
        // Create an array of promises
        let promises = checkList.map((item) => {
            let data = {
                "num": item.num,
                "comments": item.comments,
                "detail": item.detail
            }
            return axios.patch(`${process.env.REACT_APP_API_URL}/checklist/${classId}`, data, {headers: headers})
        })
        // Wait for all the promises to resolve
        Promise.all(promises)
        .then(() => {
            getAccCheckItems();
            alert("Saved!")
        })
    }
    


    // When the user submits the form, update the accessibility database and close the modal
    function handleSaveAccessibility(e){
        // The teacher's class id
        let classId = cookies.get('courseId')[0];
        e.preventDefault();
        // Create an array of promises
        let promises = accList.map((item) => {
            let data = {
                "num": item.num,
                "detail": item.detail,
                "checkbox": item.checkbox
            }
            return axios.patch(`${process.env.REACT_APP_API_URL}/accessibility/${classId}`, data, {headers: headers})
        })
        // Wait for all the promises to resolve
        Promise.all(promises)
        .then(() => {
            getAccCheckItems();
            alert("Saved!")
        })
    }
    


    // Read data from database into rows to be displayed in the accessibility table
    function createAccData(data){
        const rows = [];
        // For each item in the accessibility, create a row
        for (let i = 0; i < data.items.length; i++){
          // Add row to the rows array
          rows.push({
            num: data.items[i].num,
            description: data.items[i].category,
            // Checkbox
            isPresent:
                <div className="form-check">
                    <input 
                        className="form-check-input" 
                        type="checkbox" 
                        value="" 
                        id="flexCheckDefault"
                        defaultChecked={data.items[i].checkbox} 
                        onChange={(e) => {
                            accList[i].checkbox = e.target.checked;
                        }}
                    />
                </div>,
            // Comment box
            Comments:
                <div className="form-floating">
                    <textarea
                        className="form-control rounded-0 "
                        id="floatingTextarea2"
                        style={{height: "100px", borderRadius: "5px", width: "100%"}}
                        placeholder="Comments"
                        defaultValue={data.items[i].detail}
                        onChange={
                            (e) => {
                                accList[i].detail = e.target.value;
                            }
                        }
                    ></textarea>
                </div>     
          })
        }
        // Sort rows by num
        rows.sort((a, b) => (a.num > b.num) ? 1 : -1)
        return rows;
    }


    // Read data from database into rows to be displayed in the checklist table
    function createCheckData(data){
        const rows = [];
        // For each item in the checklist, create a row
        for (let i = 0; i < data.items.length; i++){
          // Add row to the rows array
          rows.push({
            num: data.items[i].num,
            description: data.items[i].text,
            // Checkbox
            isPresent:
                <div className="form-check">
                    <input 
                        className="form-check-input" 
                        type="checkbox" 
                        value="" 
                        id="flexCheckDefault"
                        defaultChecked={data.items[i].detail} 
                        onChange={(e) => {
                            checkList[i].detail = e.target.checked;
                        }}
                    />
                </div>,
            // Comment box    
            Comments:
                <div className="form-floating">
                    <textarea
                        className="form-control rounded-0 "
                        id="floatingTextarea2"
                        style={{height: "100px", borderRadius: "5px", width: "100%"}}
                        placeholder="Comments"
                        defaultValue={data.items[i].comments}
                        onChange={
                            (e) => {
                                checkList[i].comments = e.target.value;
                            }
                        }
                    ></textarea>
                </div>     
          })
        }
        // Sort rows by num
        rows.sort((a, b) => (a.num > b.num) ? 1 : -1)
        return rows;
    }


  return (
    <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center"
        data-testid="teacher-check-acc-modal-1"
        style={{zIndex: '5'}}>
        <div className="bg-white rounded-lg shadow-2xl w-2/3" >
        <header className="bg-gray-100 px-4 py-2 flex justify-between items-center">
                <span className="material-icons-outlined text-gray-400">
                    drag_handle
                </span>
                <div>
                    <button onClick={() => setShowTeacherCheckAccModal(false)}>
                        <span className="material-icons-outlined text-gray-400">
                            close
                        </span>
                    </button>
                </div>
            </header>
            <div>
                <div className="px-4 py-2">
                    <Tabs>
                        <TabList>
                            <Tab>Checklist</Tab>
                            <Tab>Accessibility</Tab>
                        </TabList>
                        <TabPanel className="Checklist Tab">
                        <form>
                                <div>
                                    <div style={{margin: "10px"}}>      
                                        <Paper sx={{ width: '100%', overflow: 'hidden' }} >
                                            <TableContainer
                                                sx={{
                                                    border: "4px solid rgba(0,0,0,0)",
                                                    padding:1,
                                                    height: "60vh",
                                                    margin: "auto",
                                                }}
                                            >
                                            <Table stickyHeader aria-label="sticky table">
                                            <ThemeProvider theme={theme}>
                                                
                                                <TableHead>
                                                <TableRow >
                                                    {columnsCheck.map((column) => (
                                                    <TableCell
                                                        key = {uuid()}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                    ))}
                                                </TableRow>
                                                </TableHead>
                                                </ThemeProvider>
                                                <TableBody >
                                                {rowsCheck
                                                    .slice(pageCheck * rowsPerPageCheck, pageCheck * rowsPerPageCheck + rowsPerPageCheck)
                                                    .map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key = {uuid()}>
                                                        {columnsCheck.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                            <TableCell key = {uuid()} align={column.align}>
                                                                {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}
                                                            </TableCell>
                                                            );
                                                        })}
                                                        </TableRow>
                                                    );
                                                    })}
                                                </TableBody>
                                            </Table>
                                            </TableContainer>
                                            <TablePagination
                                            rowsPerPageOptions={[10, 25, 100]}
                                            component="div"
                                            count={rowsCheck.length}
                                            rowsPerPage={rowsPerPageCheck}
                                            page={pageCheck}
                                            onPageChange={handleChangePageCheck}
                                            onRowsPerPageChange={handleChangeRowsPerPageCheck}
                                            />
                                        </Paper>
                                    <button type="submit"
                                            onClick={handleSaveCheckList}
                                            className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
                                            style={{margin: '10px', marginBottom: '5px', float: 'right'}}>
                                        Save Checklist
                                    </button>
                                    </div>
                                </div>
                            </form>
                        </TabPanel>
                        <TabPanel className="Accessibility Tab">
                        <form>
                                <div>
                                    <div style={{margin: "10px"}}>      
                                        <Paper sx={{ width: '100%', overflow: 'hidden' }} >
                                            <TableContainer  
                                                sx={{
                                                    border: "4px solid rgba(0,0,0,0)",
                                                    padding:1,
                                                    height: "60vh",
                                                    margin: "auto",
                                                }}                                 
                                            >
                                            <Table stickyHeader aria-label="sticky table">
                                            <ThemeProvider theme={theme}>
                                                <TableHead>
                                                <TableRow >
                                                    {columnsAcc.map((column) => (
                                                    <TableCell
                                                        key = {uuid()}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                    ))}
                                                </TableRow>
                                                </TableHead>
                                                </ThemeProvider>
                                                <TableBody >
                                                {rowsAcc
                                                    .slice(pageAcc * rowsPerPageAcc, pageAcc * rowsPerPageAcc + rowsPerPageAcc)
                                                    .map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key = {uuid()}>
                                                        {columnsAcc.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                            <TableCell key = {uuid()} align={column.align}>
                                                                {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}
                                                            </TableCell>
                                                            );
                                                        })}
                                                        </TableRow>
                                                    );
                                                    })}
                                                </TableBody>
                                            </Table>
                                            </TableContainer>
                                            <TablePagination
                                            rowsPerPageOptions={[10, 25, 100]}
                                            component="div"
                                            count={rowsAcc.length}
                                            rowsPerPage={rowsPerPageAcc}
                                            page={pageAcc}
                                            onPageChange={handleChangePageAcc}
                                            onRowsPerPageChange={handleChangeRowsPerPageAcc}
                                            />
                                        </Paper>
                                    </div>
                                    <button type="submit"
                                            onClick={handleSaveAccessibility}
                                            className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
                                            style={{margin: '10px', marginBottom: '5px', float: 'right'}}>
                                        Save Accessibility
                                    </button>
                                </div>
                            </form>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
    </div>
  );
}
