import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// Creates a Drop down for account types
// Account type can also be refered to as Role
// If a new account type is wanted will need to add it into drop down
export default function AccountsDropDown(props) {

  return (
    <Box sx={{ minWidth: '50%' }}>
     <FormControl 
        fullWidth
        margin='dense'>
          <InputLabel id="account-type">Account Type</InputLabel>
          <Select
            className='drop-down'
            labelId="account-select"
            name="role"
            label="Account Type"
            onChange={props.handleInputRole}
            defaultValue= {props.role}
            >
            {/* List of type of accounts currently available */}
            <MenuItem value={"TEACHER"}>Teacher</MenuItem>
            <MenuItem value={"PRESENTER"}>Presenter</MenuItem>
            <MenuItem value={"ADMIN"}>Admin</MenuItem>
          </Select>
        </FormControl>
    </Box>
  );
}
