import React, {useContext} from 'react'
import AdminContext from './../context/AdminContext';
import RuleIcon from '@mui/icons-material/Rule';

export default function AdminChecklistButton() {
    const {setShowAdminCheckAccModal} = useContext(AdminContext);
    return (
      <button
        onClick={() => {
            setShowAdminCheckAccModal(true);
        }}
        className="border p-4 rounded-full flex items-center shadow-xl hover:shadow-2xl"
      >
        <div className="w-7 h-7" >
            <RuleIcon style={{color: 'green'}}/>
        </div>
        <span className="pl-1 pr-7 text-xl font-semibold text-green-900">Checklist and Accessibility</span>
      </button>
    );
  }