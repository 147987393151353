import Navbar from "../../Components/Navbar"
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { PresenterEventsTable } from "./components/PresenterEventsTable";
// Checks what a users role is and sends them to the correct home page for their role
export default function Home() {
    const cookies = new Cookies();
    const role = cookies.get('role');
    
    const navigate = useNavigate();

    useEffect(() => {
    const cookies = new Cookies();
    if(cookies.get('access') === undefined){
        navigate("/");
    }

    }, [navigate]);
    return (
        <div name = 'homepage'>
            <Navbar />
            <PresenterEventsTable />
        </div>
    );
}