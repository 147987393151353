import * as React from 'react';
import { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditDialog from "../../Components/Dialogs/EditAccount";
import getAllUsers from '../../services/AccountList/getAllAccounts';
import addNewUser from '../../services/AccountList/newAccount.js';
import editUser from '../../services/AccountList/editAccount.js';
import { FormControl } from '@mui/material';
import AddDialog from '../../Components/Dialogs/AddAccount';
import { InputLabel } from '@mui/material';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Create layout for UsersTable columns
const columns = [
  { id: 'nameFirst', label: 'First Name', minWidth: 100 },
  { id: 'nameLast', label: 'Last Name', minWidth: 100 },
  {
    id: 'email',
    label: 'Email',
    minWidth: 120,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'role',
    label: 'Account Type',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'active',
    label: 'Active/Inactive',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'Edit',
    label: '',
    minWidth: 50,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          // Increase size of table
          fontSize: '1.3rem',
        },
      },
    },
  },
});

export default function UserListTable(props) {
  const [changes, updateChanges] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [rows, setRows] = React.useState([]);
  const [accountTypeFilter, setAccountTypeFilter] = React.useState('All');

  // function that waits for db Users promise to fulfill, then sets the table rows to their data
  async function getUserList() {
    let userList = await getAllUsers().then((res) => {
      return res.data.sets;
    });
    setRows(createData(userList));
  }

  // Refreshes table when changes are made
  useEffect(() => {
    getUserList();
  }, [changes]);

  //
  const handleAddUser = async (data) => {
    await addNewUser(data);
    updateChanges(changes + 1);
  };

  //
  const handleEditUser = async (data) => {
    await editUser(data);
    updateChanges(changes + 1);
  };

  // Handle change of user table page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //Handle change of number of rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  //Function to map all Users from the database to the table layout
  function createData(users) {
    const rows = [];   
    for (let i = 0; i < users.length; ++i){
      console.log(users[i].role);
      if (accountTypeFilter === 'All' || users[i].role === accountTypeFilter) {
        const nameFirst = (users[i].first_name ? users[i].first_name : "");
        const nameLast = (users[i].last_name ? users[i].last_name : "");
        const email = users[i].email;
        const role = users[i].role;
        const active = (users[i].is_active? "Active" : "Inactive");
        const Edit = <EditDialog
                        user={users[i]}
                        editUser={handleEditUser}
                      />
        rows.push({ nameFirst, nameLast, email, role, active, Edit });
      };
    }
    return rows;
  }
  // Handle change of account type filter
const handleChangeAccountType = (event) => {
  setAccountTypeFilter(event.target.value);
  updateChanges(changes + 1);
};
  //Rendering
  return (
    <Paper data-testid="users-table-component" id="usertable" sx={{ width: '100%', overflow: 'hidden' }}>
      <AddDialog
        addUser={handleAddUser}
      />
      <TableContainer
        sx={{
          border: "4px solid rgba(0,0,0,0)",
          padding: 1,
          margin: "auto",
        }}
      >
          <Table stickyHeader aria-label="sticky table">
          <ThemeProvider theme={theme}>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              style={{ minWidth: column.minWidth }}
            >
              {column.id === "role" ? (
                <React.Fragment>
                  <div>{column.label}</div>
                  <FormControl style={{ marginTop: '0px', width: '125px' }}>
                  <Select
                    labelId="account-type-label"
                    id="account-type-filter"
                    value={accountTypeFilter}
                    onChange={handleChangeAccountType}
                    style={{ 
                      height: '30px',                    
                    }}
                  >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="ADMIN">Admins</MenuItem>
                    <MenuItem value="TEACHER">Teachers</MenuItem>
                    <MenuItem value="PRESENTER">Presenters</MenuItem>
                  </Select>
                </FormControl>

                </React.Fragment>
              ) : (
                column.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </ThemeProvider>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.email}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}