import React from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import axios from "axios";
import Cookies from 'universal-cookie'
import AdminContext from './../context/AdminContext'
import { useContext } from 'react'

// Button to import students from a CSV file
export default function ExportStudentButton() {
  const cookies = new Cookies();
  // Get variables from AdminContext
  const {
    selectedCourseId
  } = useContext(AdminContext);
  // Header for axios requests
  const headers = {
    'Authorization': 'Bearer ' + cookies.get('access'),
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Accept': "*/*"
  }
  return (
    <button
      data-testid="export-student-button-1"
      onClick={() => {
        axios.get(`${process.env.REACT_APP_API_URL}/export/${selectedCourseId}`, { headers: headers })
          .then((response) => {
            // Download a csv file with the data
            const csvLink = document.createElement('a');
            csvLink.href = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
            // Download csv with name students + course id
            csvLink.setAttribute('download', `students${selectedCourseId}.csv`);
            document.body.appendChild(csvLink);
            csvLink.click();
            document.body.removeChild(csvLink);
          })
      }}
      className="border p-3 rounded-full flex items-center shadow-lg hover:shadow-2xl"
    >
      <div className="w-7 h-7" >
        <FileDownloadIcon style={{ color: 'green' }} />
      </div>
      <span className="pl-1 pr-7 text-md font-semibold text-green-900">Download Student Data</span>
    </button>
  );
}