import React, {useContext, useState, useEffect} from 'react'
import AdminContext from './../context/AdminContext'
import axios from "axios";
import "./style.css";
import Cookies from 'universal-cookie'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Multiselect from 'multiselect-react-dropdown';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
// ClassModal is the modal that pops up when the user clicks the add class button
// It allows the user to add a new class
export default function ClassModal() {
    // Declare variables used and set in the modal
    const [teacherEmails, setTeacherEmails] = useState([]);
    const [adminEmails, setAdminEmails] = useState([]);
    const [presenterEmails, setPresenterEmails] = useState([]);
    const cookies = new Cookies();
    const [errMsg , setErrMsg] = useState("");
    const [noTeacherError, setNoTeacherError] = useState(false);
    const [runCount, setRunCount] = useState(0);
    // Get variables from AdminContext
    const {
        school,
        setSchool,
        grade,
        setGrade,
        classroom,
        setClassroom,
        teacher,
        setTeacher,
        setShowClassModal, 
        changes,
        updateChanges,
        editClass,
        editClassId,
        admin,
        setAdmin,
        presenters,
        setPresenters,
    } = useContext(AdminContext);

    // Set up headers before calling axios
    const headers = {
        'Authorization': 'Bearer ' + cookies.get('access'),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': "*/*" 
    }
    // Get the list of teachers in the database to populate the dropdown
    useEffect(() => {
        if (runCount <= 1){
            setRunCount(runCount + 1);
            axios
                .get(`${process.env.REACT_APP_API_URL}/users/`,
                    {headers: headers})
                .then(res => {
                    var teachersWithCourses = [];
                    axios.get(`${process.env.REACT_APP_API_URL}/courses/`,
                        {headers: headers})
                        .then(res1 => {
                            for (var i = 0; i < res1.data.sets.length; i++) {
                                if (res1.data.sets[i].teacher) {
                                    for (let t of res1.data.sets[i].teacher){
                                        teachersWithCourses.push(t);
                                    }  
                                }
                            }
                            // Get id of teachers in courses
                            var teachers_emails = res.data.sets.filter(function (el) {
                                // Return users who are teachers and not assigned to courses
                                return el.role === "TEACHER" && !teachersWithCourses.includes(el.id);
                            });
                            teachers_emails = teachers_emails.map(a => a.email);
                            // If no teacher is available, display error message
                            if (teachers_emails.length === 0 && !editClass) {
                                setNoTeacherError(true);
                            }
                            
                            
                            teachers_emails = teacher.concat(teachers_emails);
                            setTeacherEmails(teachers_emails);
                        })
                    var admins_emails = res.data.sets.filter(function (el) {
                        return el.role === "ADMIN";
                    });
                    admins_emails = admins_emails.map(a => a.email);
                    setAdminEmails(admins_emails);

                    var presenters_emails = res.data.sets.filter(function (el) {
                        return el.role === "PRESENTER";
                    }
                    );
                    presenters_emails = presenters_emails.map(a => a.email);
                    setPresenterEmails(presenters_emails);
            });
        }
    }, [teacher]);


    // Post/Update the new class to the database when the save button is clicked
    function handleSubmit(e){
        e.preventDefault();
        // Check if the user has entered all the required fields
        if (school === "" || grade === "" || teacher.length === 0){
            setErrMsg("Please fill out all fields");
        } else {
            if (editClass) {
                // If the user is editing a class, update the class with patch
                // Get the id of the teacher from the email
                axios
                    .get(`${process.env.REACT_APP_API_URL}/users/`,
                        {headers: headers})
                    .then(res => {
                        // Find id of teacher from email
                        var teacher_id = res.data.sets.filter(function (el) {
                            return teacher.includes(el.email);
                        });
                        teacher_id = teacher_id.map(a => a.id);
                        // Find id of admin from email
                        var admin_id = res.data.sets.filter(function (el) {
                            return el.email === admin;
                        });
                        admin_id = admin_id[0].id;
                        // Find id of presenters from emails
                        var presenters_ids = res.data.sets.filter(function (el) {
                            return presenters.includes(el.email);
                        });
                        presenters_ids = presenters_ids.map(a => a.id);

                        const newClass={
                            "school": school,
                            "grade": grade,
                            "classroom": classroom,
                            "teacher": teacher_id,
                            "id": editClassId,
                            "admin": admin_id,
                            "presenters": presenters_ids,
                        }
                        axios
                            .patch(`${process.env.REACT_APP_API_URL}/courses/`, 
                                newClass, 
                                {headers: headers})
                            .then(res => {
                                // Close the modal
                                setShowClassModal(false);
                                // Update the changes variable to force a re-render of the classes
                                updateChanges(changes + 1);
                            })
                });
            }else{
                // If the user is adding a new class, post the class to the database
                const newClass = {
                    "school": school,
                    "grade": grade,
                    "classroom": classroom,
                    "teacher": teacher,
                    "admin": admin,
                    "presenter": presenters
                };
                axios
                    .post(`${process.env.REACT_APP_API_URL}/courses/`, 
                        newClass, 
                        {headers: headers})
                    .then(res => {
                        // Close the modal
                        setShowClassModal(false);
                        // Update the changes variable to force a re-render of the classes
                        updateChanges(changes + 1);
                    });
            }
        }
    }

  return (
    <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center"
        data-testid="class-modal-1"
        style={{zIndex: '10'}}>
        <form className="bg-white rounded-lg shadow-2xl w-1/3" >
        <header className="bg-gray-100 px-4 py-2 flex justify-between items-center">
                <span className="material-icons-outlined text-gray-400">
                    drag_handle
                </span>
                <div>
                    <button onClick={() => setShowClassModal(false)}>
                        <span className="material-icons-outlined text-gray-400">
                            close
                        </span>
                    </button>
                </div>
            </header>
            <div style={{margin:"10px"}}>
                <div className="School">
                    <input
                        type="text"
                        name="school"
                        value={school}
                        placeholder="School"
                        required
                        className="pt-3 border-0 
                        text-gray-600 text-l 
                        font-semibold pb-2 
                        w-full border-b-2 
                        border-gray-200 
                        focus:outline-none 
                        focus:ring-0
                        focus:border-blue-500"
                        // Dont show suggestions
                        autoComplete="off"
                        onChange={(e) => setSchool(e.target.value)} 
                    >
                    </input>
                </div>
                <div className="Grade">               
                    <input
                        type="text"
                        name="grade"
                        value={grade}
                        placeholder="Grade"
                        required
                        className="pt-3 border-0 
                        text-gray-600 text-l 
                        font-semibold pb-2 
                        w-full border-b-2 
                        border-gray-200 
                        focus:outline-none 
                        focus:ring-0
                        focus:border-blue-500"
                        onChange={(e) => setGrade(e.target.value)} 
                    >
                    </input>
                </div>
                <div className="Classroom">
                    <input
                        type="text"
                        name="classroom"
                        value={classroom}
                        placeholder="Classroom"
                        className="pt-3 border-0
                        text-gray-600 text-l
                        font-semibold pb-2
                        w-full border-b-2
                        border-gray-200
                        focus:outline-none
                        focus:ring-0
                        focus:border-blue-500"
                        onChange={(e) => setClassroom(e.target.value)}
                    >
                    </input>
                </div>
                <div className="Admin" >
                    <Dropdown
                        name="admin" 
                        options={adminEmails}
                        onChange={(e) => setAdmin(e.value)}
                        placeholder="Select a Facilitator"
                        value={admin}
                        className="pt-3 border-0 
                        text-gray-600 text-l 
                        font-semibold pb-2 
                        border-gray-200 
                        focus:outline-none 
                        focus:ring-0
                        focus:border-blue-500"
                    />
                </div>
                <div className="Teacher" >
                    <Multiselect
                        className="pt-3 border-0 
                        text-gray-600 text-l 
                        font-semibold pb-2 
                        border-gray-200 
                        focus:outline-none 
                        focus:ring-0
                        focus:border-blue-500"
                        name="teacher" 
                        closeOnSelect="true"
                        options={teacherEmails}
                        onSelect={(e) => setTeacher(e)}
                        onRemove={(e) => setTeacher(e)}
                        placeholder='Select Teachers'
                        value={teacher}
                        isObject={false}
                        selectedValues={teacher}
                        style={{
                            closeOnSelect: true,
                            chips: {
                                background: 'green'
                            },
                            // Remove outline from multiselect on focus
                            searchBox: {
                                outline: 'none'
                            },
                        }}
                    />
                </div>
                <div className="Presenters">
                    <Multiselect
                        closeOnSelect={true}
                        className="pt-3 border-0 
                        text-gray-600 text-l 
                        font-semibold pb-2 
                        border-gray-200 
                        focus:outline-none 
                        focus:ring-0
                        focus:border-blue-500"
                        placeholder='Select Presenters (Optional)'
                        isObject={false}
                        options={presenterEmails}
                        value={presenters}
                        selectedValues={presenters}
                        onSelect={(e) => setPresenters(e)}
                        onRemove={(e) => setPresenters(e)}
                      // style multiselect container
                        style={{
                            closeOnSelect: true,
                            chips: {
                                background: 'green'
                            },
                            // Remove outline from multiselect on focus
                            searchBox: {
                                outline: 'none'
                            },
                        }}
                    />
                </div>
                <div className="Save">
                    <button type="submit"
                        onClick={handleSubmit}
                        style={{margin: '5px', marginBottom: '5px', float: 'right'}}
                        className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
                    >
                        Save
                    </button>
                </div>
                <div className="pt-3 border-0 
                        text-gray-600 text-l 
                        font-semibold pb-2 
                        w-full 
                        border-gray-200 
                        focus:outline-none 
                        focus:border-blue-500"
                        style={{color: "red"}}>
                    {errMsg}
                </div>
            </div>
        </form>
        <Snackbar
              open={noTeacherError}
              onClose={() => setNoTeacherError(false)}
              autoHideDuration={6000}
            >
              <Alert
                severity="error"
                sx={{ width: "100%" }}
                onClose={() => setNoTeacherError(false)}
              >
                No teachers available to assign to classes.
              </Alert>
      </Snackbar>
    </div>
  );
}
