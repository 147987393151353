import axios from "axios";

// login endpoint axios
const login = (data) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/login/`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
    .then((response) => {
      return response.data;
    });

export { login };
