import axios from "axios";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

//return all users in db via axios
export default function getAllUsers() {
  return axios.get(`${process.env.REACT_APP_API_URL}/users/`,{headers: {
    'Content-Type': 'application/json',
    'Accept': '*/*',
    'Access-Control-Allow-Origin': '*',
    'Authorization' : 'Bearer '+ cookies.get('access')
  }});
}

