import React, {useContext, useEffect} from 'react'
import {ClassTable} from './items/ClassTable'
import AdminContext from './context/AdminContext'
import CreateClassButton from './items/CreateClassButton'
import ClassModal from './items/ClassModal'
import ClassInfoModal from './items/ClassInfoModal'
import AdminChecklistButton from './items/AdminChecklistButton'
import AdminCheckAccModal from './items/AdminCheckAccModal'
import ExportClassButton from './items/ExportClassButton'
import CommentModal from './items/CommentModal'
import StudentModalAdmin from './items/StudentModalAdmin'

// Home page for accounts with admin role
export const Admin = () => {
  const {
    showClassModal,
    showClassInfoModal,
    showAdminCheckAccModal,
    showCommentsModal,
    showStudentModal,
  } = useContext(AdminContext);
  useEffect (() => {
  }, [showClassModal])

  useEffect (() => {   
  }, [showClassInfoModal])

  useEffect (() => {
  }, [showAdminCheckAccModal])

  useEffect (() => {
  }, [showCommentsModal])

  useEffect (() => {
  }, [showStudentModal])

  return (
    <div style={{margin:"10px"}}>
      
      {showClassModal && <ClassModal/>}
      {showClassInfoModal && <ClassInfoModal/>}
      {showAdminCheckAccModal && <AdminCheckAccModal/>}
      {showCommentsModal && <CommentModal/>}
      {showStudentModal && <StudentModalAdmin/>}
      
      
      <div className="px-2 flex flex-row items-center float-right inline-flex">

        <div style={{marginLeft:"15px", marginBottom: "10px"}}>
          <AdminChecklistButton/>
        </div>
        <div style={{marginLeft:"15px", marginBottom: "10px"}}>
          <ExportClassButton/>
        </div>
        <div style={{marginLeft:"15px", marginBottom: "10px"}}>
          <CreateClassButton />
        </div>
      </div>    
      <ClassTable/>
    </div>
  )
}
