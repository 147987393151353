import React, {useContext, useState, useEffect} from 'react'
import GlobalContext from './context/Context'
import 'react-datepicker/dist/react-datepicker.css'
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { DateTime } from 'luxon';
import { weeksToDays } from 'date-fns';


// Course id of the current schedule
var courseId = '1';

// Ask the user to confirm the creation of the default events
export default function ConfirmModal() {
    // Get variables from the global context
    const cookies = new Cookies();
    const navigate = useNavigate();
    const headers = {
        'Authorization': 'Bearer ' + cookies.get('access'),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': "*/*" 
    }
    const {
        setShowConfirmModal,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        changes,
        updateChanges,
    } = useContext(GlobalContext);

    // Go home if course is not selected
    useEffect(() => {
        if(cookies.get('courseId') === undefined){
            navigate('/home');
        }
        else{
            courseId = (cookies.get('courseId'));
        }
    }, [])

    //Random id generator;
    let guid = () => {
        let s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + s4() + s4() + s4();
    }      

    // List of default events
    const defaultEvents = [
        // Each day 9:30am - 9:45am
        {
            "title": "Welcome:location:",
            "start": new Date(monday.getFullYear(), monday.getMonth(), monday.getDate(), 9, 30),
            "end": new Date(monday.getFullYear(), monday.getMonth(), monday.getDate(), 9, 45),
            "color": "#008000",
            "id": Date.now() + guid()
        },
        {
            "title": "Welcome:location:",
            "start": new Date(tuesday.getFullYear(), tuesday.getMonth(), tuesday.getDate(), 9, 30),
            "end": new Date(tuesday.getFullYear(), tuesday.getMonth(), tuesday.getDate(), 9, 45),
            "color": "#008000",
            "id": Date.now() + guid()
        },
        {
            "title": "Welcome:location:",
            "start": new Date(wednesday.getFullYear(), wednesday.getMonth(), wednesday.getDate(), 9, 30),
            "end": new Date(wednesday.getFullYear(), wednesday.getMonth(), wednesday.getDate(), 9, 45),
            "color": "#008000",
            "id": Date.now() + guid()
        },
        {
            "title": "Welcome:location:",
            "start": new Date(thursday.getFullYear(), thursday.getMonth(), thursday.getDate(), 9, 30),
            "end": new Date(thursday.getFullYear(), thursday.getMonth(), thursday.getDate(), 9, 45),
            "color": "#008000",
            "id": Date.now() + guid()
        },
        {
            "title": "Welcome:location:",
            "start": new Date(friday.getFullYear(), friday.getMonth(), friday.getDate(), 9, 30),
            "end": new Date(friday.getFullYear(), friday.getMonth(), friday.getDate(), 9, 45),
            "color": "#008000",
            "id": Date.now() + guid()
        },
        // Travel every day 9:45am - 10:00am
        {
            "title": "Travel:location:",
            "start": new Date(monday.getFullYear(), monday.getMonth(), monday.getDate(), 9, 45),
            "end": new Date(monday.getFullYear(), monday.getMonth(), monday.getDate(), 10, 0),
            "color": "#008000",
            "id": Date.now() + guid()
        },
        {
            "title": "Travel:location:",
            "start": new Date(tuesday.getFullYear(), tuesday.getMonth(), tuesday.getDate(), 9, 45),
            "end": new Date(tuesday.getFullYear(), tuesday.getMonth(), tuesday.getDate(), 10, 0),
            "color": "#008000",
            "id": Date.now() + guid()
        },
        {
            "title": "Travel:location:",
            "start": new Date(wednesday.getFullYear(), wednesday.getMonth(), wednesday.getDate(), 9, 45),
            "end": new Date(wednesday.getFullYear(), wednesday.getMonth(), wednesday.getDate(), 10, 0),
            "color": "#008000",
            "id": Date.now() + guid()
        },
        {
            "title": "Travel:location:",
            "start": new Date(thursday.getFullYear(), thursday.getMonth(), thursday.getDate(), 9, 45),
            "end": new Date(thursday.getFullYear(), thursday.getMonth(), thursday.getDate(), 10, 0),
            "color": "#008000",
            "id": Date.now() + guid()
        },
        {
            "title": "Travel:location:",
            "start": new Date(friday.getFullYear(), friday.getMonth(), friday.getDate(), 9, 45),
            "end": new Date(friday.getFullYear(), friday.getMonth(), friday.getDate(), 10, 0),
            "color": "#008000",
            "id": Date.now() + guid()
        },
        // Journaling every day 11:00am - 11:30am
        {
            "title": "Journaling:location:",
            "start": new Date(monday.getFullYear(), monday.getMonth(), monday.getDate(), 11, 0),
            "end": new Date(monday.getFullYear(), monday.getMonth(), monday.getDate(), 11, 30),
            "color": "#0000FF",
            "id": Date.now() + guid()
        },
        {
            "title": "Journaling:location:",
            "start": new Date(tuesday.getFullYear(), tuesday.getMonth(), tuesday.getDate(), 11, 0),
            "end": new Date(tuesday.getFullYear(), tuesday.getMonth(), tuesday.getDate(), 11, 30),
            "color": "#0000FF",
            "id": Date.now() + guid()
        },
        {
            "title": "Journaling:location:",
            "start": new Date(wednesday.getFullYear(), wednesday.getMonth(), wednesday.getDate(), 11, 0),
            "end": new Date(wednesday.getFullYear(), wednesday.getMonth(), wednesday.getDate(), 11, 30),
            "color": "#0000FF",
            "id": Date.now() + guid()
        },
        {
            "title": "Journaling:location:",
            "start": new Date(thursday.getFullYear(), thursday.getMonth(), thursday.getDate(), 11, 30),
            "end": new Date(thursday.getFullYear(), thursday.getMonth(), thursday.getDate(), 12, 0),
            "color": "#0000FF",
            "id": Date.now() + guid()
        },
        {
            "title": "Journaling:location:",
            "start": new Date(friday.getFullYear(), friday.getMonth(), friday.getDate(), 11, 0),
            "end": new Date(friday.getFullYear(), friday.getMonth(), friday.getDate(), 11, 30),
            "color": "#0000FF",
            "id": Date.now() + guid()
        },
        // Lunch every day 11:30am - 12:15pm
        {
            "title": "Lunch:location:",
            "start": new Date(monday.getFullYear(), monday.getMonth(), monday.getDate(), 11, 30),
            "end": new Date(monday.getFullYear(), monday.getMonth(), monday.getDate(), 12, 15),
            "color": "#008080",
            "id": Date.now() + guid()
        },
        {
            "title": "Lunch:location:",
            "start": new Date(tuesday.getFullYear(), tuesday.getMonth(), tuesday.getDate(), 11, 30),
            "end": new Date(tuesday.getFullYear(), tuesday.getMonth(), tuesday.getDate(), 12, 15),
            "color": "#008080",
            "id": Date.now() + guid()
        },
        {
            "title": "Lunch:location:",
            "start": new Date(wednesday.getFullYear(), wednesday.getMonth(), wednesday.getDate(), 11, 30),
            "end": new Date(wednesday.getFullYear(), wednesday.getMonth(), wednesday.getDate(), 12, 15),
            "color": "#008080",
            "id": Date.now() + guid()
        },
        {
            "title": "Lunch:location:",
            "start": new Date(friday.getFullYear(), friday.getMonth(), friday.getDate(), 11, 30),
            "end": new Date(friday.getFullYear(), friday.getMonth(), friday.getDate(), 12, 15),
            "color": "#008080",
            "id": Date.now() + guid()
        },
        // Travel every day 12:15pm - 12:30pm
        {
            "title": "Travel:location:",
            "start": new Date(monday.getFullYear(), monday.getMonth(), monday.getDate(), 12, 15),
            "end": new Date(monday.getFullYear(), monday.getMonth(), monday.getDate(), 12, 30),
            "color": "#008000",
            "id": Date.now() + guid()
        },
        {
            "title": "Travel:location:",
            "start": new Date(tuesday.getFullYear(), tuesday.getMonth(), tuesday.getDate(), 12, 15),
            "end": new Date(tuesday.getFullYear(), tuesday.getMonth(), tuesday.getDate(), 12, 30),
            "color": "#008000",
            "id": Date.now() + guid()
        },
        {
            "title": "Travel:location:",
            "start": new Date(wednesday.getFullYear(), wednesday.getMonth(), wednesday.getDate(), 12, 15),
            "end": new Date(wednesday.getFullYear(), wednesday.getMonth(), wednesday.getDate(), 12, 30),
            "color": "#008000",
            "id": Date.now() + guid()
        },
        {
            "title": "Travel:location:",
            "start": new Date(friday.getFullYear(), friday.getMonth(), friday.getDate(), 12, 15),
            "end": new Date(friday.getFullYear(), friday.getMonth(), friday.getDate(), 12, 30),
            "color": "#008000",
            "id": Date.now() + guid()
        },
        // Explore & Journaling 1:30pm - 2:00pm
        {
            "title": "Explore & Journaling:location:",
            "start": new Date(monday.getFullYear(), monday.getMonth(), monday.getDate(), 13, 30),
            "end": new Date(monday.getFullYear(), monday.getMonth(), monday.getDate(), 14, 0),
            "color": "#008000",
            "id": Date.now() + guid()
        },
        {
            "title": "Explore & Journaling:location:",
            "start": new Date(tuesday.getFullYear(), tuesday.getMonth(), tuesday.getDate(), 13, 30),
            "end": new Date(tuesday.getFullYear(), tuesday.getMonth(), tuesday.getDate(), 14, 0),
            "color": "#008000",
            "id": Date.now() + guid()
        },
        {
            "title": "Explore & Journaling:location:",
            "start": new Date(wednesday.getFullYear(), wednesday.getMonth(), wednesday.getDate(), 13, 30),
            "end": new Date(wednesday.getFullYear(), wednesday.getMonth(), wednesday.getDate(), 14, 0),
            "color": "#008000",
            "id": Date.now() + guid()
        },
        {
            "title": "Explore & Journaling:location:",
            "start": new Date(friday.getFullYear(), friday.getMonth(), friday.getDate(), 13, 30),
            "end": new Date(friday.getFullYear(), friday.getMonth(), friday.getDate(), 14, 0),
            "color": "#008000",
            "id": Date.now() + guid()
        }
    ]

    // Post all default events when the user confirms the default schedule
    function handleSubmit(e) {
        e.preventDefault()
        // Post each event in defaultEvents
        axios
        .post(`${process.env.REACT_APP_API_URL}/schedule/${courseId}`, defaultEvents,
            {headers: headers})
        .then(res => {
            // Close the modal and refresh the table
            updateChanges(changes + 1);
            setShowConfirmModal(false);
        });
    }
  
    return (
    <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center"
        data-testid="confirm-modal-1"
        style={{zIndex: '5'}}>
        <form className="bg-white rounded-lg shadow-2xl w-1/3">
            <header className="bg-gray-100 px-4 py-2 flex justify-between items-center">
                <span className="material-icons-outlined text-gray-400">
                    drag_handle
                </span>
                <div>
                    <button onClick={() => setShowConfirmModal(false)}>
                        <span className="material-icons-outlined text-gray-400">
                            close
                        </span>
                    </button>
                </div>
            </header>
            <div>
                <div
                    className="pt-3 border-0 
                            text-gray-600 text-l 
                            font-semibold pb-2 
                            w-full 
                            border-gray-200 
                            flex justify-center items-center">
                    Would you like to add default events to the week of <br></br>
                </div>
                <div
                    className="pt-3 border-0 
                            text-gray-600 text-l 
                            font-semibold pb-2 
                            w-full border-b-2 
                            border-gray-200 
                            flex justify-center items-center">
                    {
                        monday.toLocaleDateString('en-US', {weekday: 'short', month: 'short', day: 'numeric'}) + 
                        " - " + 
                        friday.toLocaleDateString('en-US', {weekday: 'short', month: 'short', day: 'numeric'})
                    }
                </div>
                <div className="gSave" style={{position: "relative", textAlign:"right"}}>
                    <button 
                        onClick={() => setShowConfirmModal(false)}  
                        className="bg-red-500 hover:bg-red-600 px-6 py-2 rounded text-white" 
                        style={{margin: "10px"}}>
                        No
                    </button>
                    <button type="submit" 
                            onClick={handleSubmit}
                            className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
                            style={{margin: "10px"}}
                            >
                        Yes
                    </button>
                </div>
            </div>
        </form>
    </div>
  );
}
